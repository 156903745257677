
import { defineComponent } from "vue";
import { markdownToHtml, renderMdTemplate } from "@/services/utils/cleanData";
/**
 * @todo
 */
export default defineComponent({
  name: "RenderMd",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const renderMd = () => {
      if (!props.data) return markdownToHtml(props.value);
      return markdownToHtml(renderMdTemplate(props.data, props.value));
    };
    return {
      renderMd,
    };
  },
});
