
import { defineComponent } from "vue";
import ContentRow from "@/components/ContentRow.vue";
import HeadingRow from "@/components/HeadingRow.vue";
import BaseOrderedList from "@/components/BaseOrderedList.vue";
import ImageDescription from "@/components/ImageDescription.vue";
import { markdownToHtml, trimBodyCharacters } from "@/services/utils/cleanData";
export default defineComponent({
  name: "DuplicateModuleModalSlot",
  components: {
    ContentRow,
    HeadingRow,
    BaseOrderedList,
    ImageDescription,
  },
  props: {
    moduleData: {
      required: true,
    },
  },
  methods: {
    trimBodyCharacters(text: string) {
      return trimBodyCharacters(text);
    },

    evaluationMdToHtml(text: string) {
      return markdownToHtml(text);
    },
  },
});
