
import {defineComponent} from "vue";
import {Modal} from "bootstrap";

/**
 * BaseModalTrigger component to be used together
 * with BaseModalView
 *
 * Reasons:
 *    Sometimes when using nested modals our BaseModal implementation
 *    fails for many reasons, like it's mounted with every trigger (btn),
 *    also when using a watch hook when the competencyId changes,the hook
 *    is fired for every item in the list, also all implementations.
 *    on onMount are fired for each, which raises a performance issue.
 *
 *
 * How it works:
 *    We add each trigger to the item it belongs to, and just one
 *    BaseModalView on the page, to be controlled by each trigger.
 *
 */

export default defineComponent({
  name: "BaseModalTrigger",
  emits: ["open-modal"],
  props: {
    isNested: {
      type: Boolean,
      default: false
    },
    nestIds: {
      type: Array,
      required: false,
      description: "Array of Ids, inorder of depth, from the top most parent," +
          "to the inner most child in the nest.",
    },
    modalId: {
      type: String,
      required: true
    },
    isButton: {
      type: Boolean,
      default: false
    },
    openBtnText: {
      type: String,
      default: "..."
    },
  },
  setup(props, context) {

    /**
     * @todo pass parents inorder of nest level
     * loop through parents, and activate their modal
     * in order of tree.
     */

    const updateElementClasses = (modalId: string) => {

      // @todo if nested, loop through the modalIds

      // Update modal classes too to include show classes
      // and/or styles

      const modalDoc: any = document.getElementById(modalId)
      if (modalDoc?.classList.contains("show")) {
        modalDoc.classList.add("show")
        modalDoc.removeAttribute("aria-hidden")
        modalDoc.setAttribute("aria-modal", "true")
        modalDoc.setAttribute("role", "dialog")
        // aria-modal="true"
        // role="dialog"

        // remove aria-hidden="true"
      }



    }

    const open = (modalData: any) => {
      const modal: any = {}
      // Update parent body element class.
      // if (!document.body.classList.contains("modal-open")) {
      //   document.body.classList.add("modal-open")
      // }

      // document.body.classList.add("modal-open")

      if (!props.isNested) {
        modal.doc = document.getElementById(props?.modalId)
        modal.doc.addEventListener("shown.bs.modal", () => {
          updateElementClasses(props.modalId)
        })
        modal.value = Modal.getOrCreateInstance(modal.doc)
        modal.value.show()
        // modal.value.toggle()
        context.emit("open-modal", modalData)
      } else {

        // show all nested modals in that order.
        props.nestIds?.forEach((modalId: any) => {
          modal.doc = document.getElementById(modalId)
          modal.doc.addEventListener("shown.bs.modal", () => {
            updateElementClasses(modalId)
          })
          modal.value = Modal.getOrCreateInstance(modal.doc)
          // This causes a dark overlay behind the modal, everytime
          // an inner modal is opened!
          // To solve this toggle() through all parent modals and only,
          // show() on the inner most modal
          modal.value.show()
          //modal.value.toggle()
        });

        context.emit("open-modal", modalData)

      }
    }

    /**
     * Opens modal and notified the parent
     * about it via open-modal event
     */
    const triggerOpenModal = (modalData: any) => {
      open(modalData)
    }

    return {triggerOpenModal}
  }
})

