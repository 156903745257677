

import {defineComponent, computed, reactive, toRefs} from "vue";
import BaseTextArea from "@/components/BaseTextArea.vue";

/**
 * Used
 * @todo make props simpler!
 */
export default defineComponent({
  components: {BaseTextArea},
  emits: ['new-tag', 'selected-items'],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "Select"
    },
    modelValue: {
      // the selectedItems
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    uniqueProperty: {
      type: String,
      defaults: "id"
    },
    labelProperty: {
      type: String,
      defaults: "name"
    },
    listHeight: {
      type: String,
      default: "20vh"
    }
  },
  setup(props: any, context: any) {
    const state = reactive({
      focused: false,
      optionsTop: "34px",
      searchTerm: "",
    })

    // onMounted(() => {
    //   fixTopPosition()
    // })

    const handleInputMouseOver = () => {
      if (!state.focused) state.focused = true
    }
    const handleInputMouseLeave = () => {
      // if (focused.value) focused.value = false
      console.log("test on the list too")
    }

    /**
     * Removes or adds item from the
     * selected[] if they exists or if
     * they don't respectively.
     * Also fires an event id "selected-items"
     * so parent's can use that extensively
     * @param{any} option
     */
    const handleOptionClick = (option: any) => {
      if (!props.modelValue?.length) return [{...option, checked: true}]

      const selected: any = {value: []};
      selected.value = [...props.modelValue]
      const exists = selected.value.findIndex((v: any) => v[props.uniqueProperty] === option[props.uniqueProperty])
      if (exists === -1 && Object.keys(option)){
        selected.value.push(option)
      } else {
        selected.value.splice(exists, 1)
      }
      context.emit('selected-items', selected.value);
      return selected.value
    }
    const handleOptionRemove = (e: any,option: any) => {
      console.log('not needed for now')
    }

    const preventClose = (e: any) => {
      e.stopPropagation()
    }

    // const fixTopPosition = () => {
    //   optionsTop.value = optionsParent?.value?.clientHeight + 2 + "px"
    // }


    // computed items list on new-tag

    // computed selected options too

    /**
     * Filters options vs selected in relation
     * to search term (search list results)
     */
    const filteredOptions = computed(() => {
      const results: any = {}
      results.value = []

      if (props.options?.length && props.modelValue?.length) {

        const formattedItems: any[] = [...props.options].map((option: any) => {
          const isSelected = [...props
              .modelValue]
              ?.some((item: any) => {
                return item[props.uniqueProperty] ? item[props.uniqueProperty] === option[props.uniqueProperty] : false
              })
          return {...option, checked: isSelected}
        })
        results.value = formattedItems;
      }
      else {
        results.value = [...props.options]
      }

      if (state.searchTerm) {
        results.value = [...results.value]
            .filter((item: any) => {
              // @todo better with regex instead for more accurate search in regard to any input(s)
              // @todo also search for multiple inputs as array values separated by new lines.
              return item[props.labelProperty].toLowerCase().includes(state.searchTerm.toLowerCase()
              )})
      }

      results.value = [...results.value].filter((item) => !item.checked)
      results.selected = [...props.modelValue]
      return results

    });

    /**
     * Emits search text value
     * when enter key is pressed
     * @param{string} newTag
     */
    const handleSearchTermSubmit = async (newTag: string) => {
      if (newTag.length > 3) {
        if (!filteredOptions.value.length) {
          // We only emit new-tag if search list is empty
          context.emit('new-tag', newTag);
          state.searchTerm = "";
          return newTag;
        }
      }
    }

    return {
      ...toRefs(state),
      preventClose,
      handleOptionClick,
      handleSearchTermSubmit,
      handleOptionRemove,
      handleInputMouseOver,
      handleInputMouseLeave,
      filteredOptions,
    }
  }
})
