
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getPreviewItem } from "@/services/httpService";
import { formatDate } from "@/services/utils/formatDateString";
import RenderMd from "@/components/RenderMd.vue";

export default defineComponent({
  name: "CompetencyPreview",
  components: { RenderMd },
  data() {
    const competency: unknown = undefined;
    const decommissioned: unknown[] = [];
    const orgs: unknown[] = [];
    const modules: unknown[] = [];
    const organization: unknown = {};
    const {
      params: { id },
    } = useRoute();
    return { competency, id, decommissioned, orgs, organization, modules };
  },
  async mounted() {
    const data = await getPreviewItem("competencies", `${this.id}/preview`);
    data.data.created = formatDate(data.data.created);
    this.competency = data;
    this.organization = data.organization;
    this.modules = data.modules;
    // TODO: if data is undefined set error!!
  },
  methods: {
    getOrgName(orgId: string) {
      // eslint-disable-next-line
      const foundOrg: any = this.orgs.filter((org: any) => {
        return org.id === orgId;
      });
      if (foundOrg[0]) return foundOrg[0].name;
    },
  },
});
