
import { defineComponent, ref } from "vue";
import { getItemList, searchItems } from "@/services/httpService";
import { useStore } from "@/store";
// import SearchForm from "@/components/SearchForm.vue";
import { organizationsNames } from "@/services/utils/cleanData";

export default defineComponent({
  name: "Competencies",
  components: {},
  data() {
    const competencies: unknown[] = [];
    const orgs: unknown[] = [];
    const orgsRelated: unknown = {};
    return { competencies, orgs, orgsRelated };
  },
  setup() {
    const store = ref<unknown>(useStore());
    return { store };
  },
  async mounted() {
    // eslint-disable-next-line
    const data: any = await getItemList("competencies");
    if (data.error) return;
    const relatedOrgs =
      data.organizationsRelatedCompetencies?.competencyOrganizations;
    this.competencies = data.competencies;
    this.orgs = data.organizations;
    this.orgsRelated = relatedOrgs ? relatedOrgs : {};
  },
  methods: {
    organizationsNames(id: string) {
      // @todo fix on!!! error if no orgs
      return organizationsNames(this.orgs, this.orgsRelated, id);
    },
    getOrgName(orgId: string) {
      // eslint-disable-next-line
      const foundOrg: any = this.orgs.filter((org: any) => {
        return org.id === orgId;
      });
      if (foundOrg[0]) return foundOrg[0].name;
    },
    trimBodyCharacters(text: string) {
      if (text.length < 50) return text;
      return text.substring(0, 50).trimEnd() + "...";
    },
    // async searchCompetencies(term: string) {
    //   const items = await searchItems('competencies', term)
    //   this.competencies = items.hits;
    // },
    async searchTermSubmit(term: string) {
      /*const data = await getItemList(`competencies?search=${term}`);
      this.competencies = data;
      const message = `Search results for term: ${term}, yielded ${data.length} items. `
      await this.store.dispatch('SET_MESSAGE',message)*/
      const items = await searchItems("competencies", term);
      /**
       * todo fix missing data properties for the search
       */
      this.competencies = items;
    },
  },
});
