
import { defineComponent, ref, onUpdated } from "vue";
import Status from "@/components/Status.vue";
import { mapGetters } from "vuex";
import { useStore } from "@/store";
import { auth } from "@/services/fireBase";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Header",
  components: {
    Status,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const menuName = ref("Guest");

    onUpdated(() => {
      let name = "Guest";
      if (store.state.roles?.super_admin) name = "Admin";
      if (store.state.roles?.evaluator) name = "Evaluator";
      menuName.value = name;
    });
    // const roles = ref(store.value.state.roles)
    // const authIsReady = ref(store.value.state.authIsReady)
    // // auth.signOut().then();
    // // store.value.dispatch('user', null).then();
    // // const roles = computed(() => {
    // //   return store.value.state.roles
    // // })
    //
    // onMounted(() => {
    //   console.log("}}}}}}}}}}}} user }}}}}}}}}}}}}}}")
    //   console.log("}}}}}}}}}}}} user }}}}}}}}}}}}}}}")
    //   console.log(user)
    //   console.log("}}}}}}}}}}}} user }}}}}}}}}}}}}}}")
    //   console.log("}}}}}}}}}}}} user }}}}}}}}}}}}}}}")
    //   user.value = store.value.state.user
    //   roles.value = store.value.state.roles
    //   authIsReady.value = store.value.state.authIsReady
    // });
    //

    // let name = "Guest"
    // if (store.value.state.roles?.super_admin) name = "Admin"
    // if (store.value.state.roles?.evaluator) name = "Evaluator"
    //
    // const menuName = ref(name)
    //
    // // watch(user, (value) => {
    // //    // user.value = store.state.user
    // //   if (value) {
    // //     console.log(store.state.user)
    // //     console.log(store.state.user)
    // //     console.log(store.state.user)
    // //     console.log(store.state.user)
    // //     console.log(store.state.user)
    // //   }
    // // })

    // const menuName = () => {
    //   let name = "Guest"
    //   if (store.state.roles?.super_admin) name = "Admin"
    //   if (store.state.roles?.evaluator) name = "Evaluator"
    //   return name;
    // }
    const handleLogout = async () => {
      await auth.signOut();
      await store.dispatch("user", null);
      await router.push("/");
    };

    // return {store, handleLogout, authIsReady, roles, menuName};
    return { handleLogout, menuName };
  },
  computed: {
    ...mapGetters(["user", "authIsReady", "userRoles"]),
  },
});
