
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getPreviewItem } from "@/services/httpService";
import { formatDate } from "@/services/utils/formatDateString";
import BaseOrderedList from "@/components/BaseOrderedList.vue";
import RenderMd from "@/components/RenderMd.vue";

export default defineComponent({
  name: "ResourcePreview",
  components: { BaseOrderedList, RenderMd },
  data() {
    const resource: unknown = undefined;
    const organization: unknown = {};
    const competenciesRelated: unknown[] = [];
    const {
      params: { id },
    } = useRoute();
    return { resource, id, organization, competenciesRelated };
  },
  async mounted() {
    const data = await getPreviewItem("resources", `${this.id}/preview`);
    data.data.created = formatDate(data.data.created);
    data.data.updated = formatDate(data.data.updated);
    this.resource = data;
    // this.organization = data.organization;
    // this.competenciesRelated = data.competenciesRelated;
  },
  methods: {
    /*getOrgName(orgId: string) {
      const foundOrg: any = this.orgs.filter((org: any) => {
        return org.id === orgId;
      })
      if (foundOrg[0]) return foundOrg[0].name
      else return "None"
    }*/
  },
});
