import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  key: 0,
  class: "px-4 py-5 my-5 text-center"
}
const _hoisted_3 = {
  key: 0,
  class: "bg-warning m-1 p-1 text-center"
}
const _hoisted_4 = { class: "display-5 fw-bold" }
const _hoisted_5 = { class: "col-lg-6 mx-auto" }
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = { class: "mb-4" }
const _hoisted_8 = { class: "mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_render_md = _resolveComponent("render-md")!
  const _component_BaseOrderedList = _resolveComponent("BaseOrderedList")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.resource)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (this.resource.data.archived)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Item Archived! "))
            : _createCommentVNode("", true),
          _createElementVNode("h1", _hoisted_4, _toDisplayString(this.resource.data.name), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_render_md, {
              label: "Body",
              value: this.resource.data.body
            }, null, 8, ["value"]),
            _createElementVNode("p", _hoisted_6, "Tags: " + _toDisplayString(this.resource.data.tags), 1),
            _createElementVNode("p", _hoisted_7, "Created: " + _toDisplayString(this.resource.data.created), 1),
            _createElementVNode("p", _hoisted_8, "Updated: " + _toDisplayString(this.resource.data.updated), 1)
          ]),
          _createVNode(_component_BaseOrderedList, {
            "list-title": 'Resource Competencies',
            "display-key": 'body',
            items: this.resource.relatedCompetencies,
            "link-to": 'CompetencyPreview'
          }, null, 8, ["items"])
        ]))
      : _createCommentVNode("", true)
  ]))
}