
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getItem } from "@/services/httpService";
import { formatDate } from "@/services/utils/formatDateString";
import BaseOrderedList from "@/components/BaseOrderedList.vue";

export default defineComponent({
  name: "Competency",
  components: {
    BaseOrderedList,
  },
  data() {
    const competency: unknown = undefined;
    const decommissioned: unknown[] = [];
    const relatedModules: unknown[] = [];
    const relatedResources: unknown[] = [];
    const orgs: unknown[] = [];
    const {
      params: { id },
    } = useRoute();
    return {
      competency,
      id,
      decommissioned,
      orgs,
      relatedModules,
      relatedResources,
    };
  },
  async mounted() {
    const data = await getItem("competencies", `${this.id}`);
    if (!data.error) {
      data.data.created = formatDate(data.data.created);
      this.competency = data;
      this.relatedModules = data.relatedModules;
      this.relatedResources = data.relatedResources;
      this.decommissioned = data.decommissionedCompetencies;
    }

    //@todo if data is undefined set error!!
  },
  methods: {
    getOrgName(orgId: string) {
      /*const foundOrg: any = this.orgs.filter((org: any) => {
        return org.id === orgId;
      })
      if (foundOrg[0]) return foundOrg[0].data.name*/
      console.log(orgId);
      return "HERE";
    },
    // eslint-disable-next-line
    getModules(modules: any) {
      return JSON.parse(modules);
    },
  },
});
