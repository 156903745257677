import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger d-flex justify-content-between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.message)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.message), 1))
    : _createCommentVNode("", true)
}