import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name", "placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("textarea", _mergeProps({
    ..._ctx.$attrs,
    onInput: (event) => _ctx.$emit('update:modelValue', event.target.value)
  }, {
      name: _ctx.label,
      class: "field",
      placeholder: _ctx.label,
      value: _ctx.modelValue
    }), null, 16, _hoisted_1)
  ], 64))
}