import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["id", "tabindex", "aria-labelledby"]
const _hoisted_4 = { class: "modal-dialog modal-dialog-centered modal-dialog-scrollable" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = { class: "modal-header" }
const _hoisted_7 = ["id"]
const _hoisted_8 = { class: "modal-body text-black" }
const _hoisted_9 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isButton)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          role: "button",
          class: "btn btn-primary btn-lg w-100 m-1",
          href: '#' + _ctx.modalId,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
        }, _toDisplayString(_ctx.openBtnText), 9, _hoisted_1))
      : (_openBlock(), _createElementBlock("span", _mergeProps({
          key: 1,
          role: "button",
          class: "text-black",
          href: '#' + _ctx.modalId
        }, {
            ..._ctx.$attrs,
            onClick: _ctx.open
              }), _toDisplayString(_ctx.openBtnText), 17, _hoisted_2)),
    _createElementVNode("div", {
      class: "modal fade",
      id: _ctx.modalId,
      tabindex: _ctx.tabIndex,
      "aria-labelledby": _ctx.modalId + 'Label',
      "aria-hidden": "true"
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h5", {
              class: "modal-title",
              id: _ctx.modalId + 'Label'
            }, _toDisplayString(_ctx.modalTitle), 9, _hoisted_7),
            _createElementVNode("button", _mergeProps({
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, {
            ..._ctx.$attrs,
            onClick: ($event) => _ctx.close($event)
              }), null, 16)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", _mergeProps({
              type: "button",
              class: "btn btn-secondary",
              "data-bs-dismiss": "modal"
            }, {
            ..._ctx.$attrs,
            onClick: ($event) => _ctx.close($event)
              }), _toDisplayString(_ctx.closeBtnText), 17),
            _createElementVNode("button", _mergeProps({
              type: "button",
              class: "btn btn-primary",
              "data-bs-dismiss": "modal"
            }, {
            ..._ctx.$attrs,
            onClick: ($event) => _ctx.save($event)
              }), _toDisplayString(_ctx.saveBtnText), 17)
          ])
        ])
      ])
    ], 8, _hoisted_3)
  ], 64))
}