
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getItem } from "@/services/httpService";
import { formatDate } from "@/services/utils/formatDateString";
import ContentRow from "@/components/ContentRow.vue";
import BaseOrderedList from "@/components/BaseOrderedList.vue";
import HeadingRow from "@/components/HeadingRow.vue";
import ImageDescription from "@/components/ImageDescription.vue";
import RenderMd from "@/components/RenderMd.vue";
import { trimBodyCharacters } from "@/services/utils/cleanData";

export default defineComponent({
  name: "Module",
  components: {
    /*ExportModule,*/
    ContentRow,
    HeadingRow,
    BaseOrderedList,
    ImageDescription,
    RenderMd,
  },
  data() {
    const {
      params: { id },
    } = useRoute();
    const module: unknown = undefined;
    const competencies: unknown[] = [];
    const relatedCompetencies: unknown[] = [];
    const resources: unknown = { learning: [], practice: [] };
    return {
      module,
      id,
      resources,
      competencies,
      relatedCompetencies,
    };
  },
  async mounted() {
    const data = await getItem("modules", `${this.id}`);

    data.data.created = formatDate(data.data?.created);
    data.data.updated = formatDate(data.data?.updated);
    data.data.expiration = formatDate(data.data?.expiration);

    this.relatedCompetencies = data.relatedCompetencies;
    delete data.competencies;

    this.module = data;
  },
  methods: {
    trimBodyCharacters(text: string) {
      return trimBodyCharacters(text);
    },
  },
});
