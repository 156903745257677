import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "button",
    class: "btn btn-warning btn-lg w-100 m-1",
    value: "Archive",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.flagEntity && _ctx.flagEntity(...args)))
  }))
}