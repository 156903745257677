import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import Competencies from "@/views/Competencies.vue";
import Competency from "@/views/Competency.vue";
import CompetencyForm from "@/views/CompetencyForm.vue";
import Module from "@/views/Module.vue";
import Modules from "@/views/Modules.vue";
import ModuleForm from "@/views/ModuleForm.vue";
import Organizations from "@/views/Organizations.vue";
import Organization from "@/views/Organization.vue";
import OrganizationForm from "@/views/OrganizationForm.vue";
import Resources from "@/views/Resources.vue";
import Resource from "@/views/Resource.vue";
import ResourceForm from "@/views/ResourceForm.vue";
import NotFoundComponent from "@/components/NotFoundComponent.vue";
import UserLogin from "@/views/UserLogin.vue";
import ProgramForm from "@/views/ProgramForm.vue";
import Programs from "@/views/Programs.vue";
import Program from "@/views/Program.vue";
import EvaluationForm from "@/views/EvaluationForm.vue";
import Evaluations from "@/views/Evaluations.vue";
import Evaluation from "@/views/Evaluation.vue";
// import ModulePreview from "@/views/ModulePreview.vue";
import ProgramModulePreview from "@/views/ProgramModulePreview.vue";
import CompetencyPreview from "@/views/CompetencyPreview.vue";
import OrganizationPreview from "@/views/OrganizationPreview.vue";
import ResourcePreview from "@/views/ResourcePreview.vue";
import ProgramPreview from "@/views/ProgramPreview.vue";
import EvaluationPreview from "@/views/EvaluationPreview.vue";
import WebHooks from "@/views/WebHooks.vue";
import WebHookForm from "@/views/WebHookForm.vue";
import WebHook from "@/views/WebHook.vue";
import StartEvaluation from "@/views/StartEvaluation.vue";
import MyModules from "@/views/MyModules.vue";
import Users from "@/views/Users.vue";
// const Login = import("@/views/Login.vue");
// const Register = import("@/views/Register.vue");
// const Users = import("@/views/Users.vue");
// const User = import("@/views/User.vue");
import TestComponent from "@/views/TestComponent.vue";
import MyAccount from "@/views/MyAccount.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    // @todo remove when done
    path: "/test",
    name: "TestComponent",
    component: TestComponent,
  },
  {
    path: "/user-login",
    name: "UserLogin",
    component: UserLogin,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  /*
{
  path: '/user/:id?', // no need for user id, already stored in firestore
  name: 'User',
  component: User
},
{
  path: '/forgot-password',
  name: 'ForgotPassword',
  component: ForgotPassword
},
{
  path: '/reset-password/:token',
  name: 'Reset',
  component: Reset
},*/
  {
    path: "/competencies",
    name: "Competencies",
    component: Competencies,
  },
  {
    path: "/competencies/:id",
    name: "Competency",
    component: Competency,
  },
  {
    path: "/competencies/:id/preview",
    name: "CompetencyPreview",
    component: CompetencyPreview,
  },
  {
    path: "/competency-create/:id?",
    // optional we use the same form/view for update and create.
    name: "CompetencyForm",
    component: CompetencyForm,
  },
  {
    path: "/modules",
    name: "Modules",
    component: Modules,
  },
  {
    path: "/modules/:id",
    name: "Module",
    component: Module,
  },
  // {
  //   path: "/programs/:programId/modules/:moduleId/preview",
  //   name: "ModulePreview",
  //   component: ModulePreview,
  // },
  {
    path: "/programs/:programId/modules/:moduleId/preview",
    name: "ProgramModulePreview",
    component: ProgramModulePreview,
  },
  {
    path: "/module-create/:id?",
    name: "ModuleForm",
    component: ModuleForm,
  },
  /*{
    path: '/modules/:id?view-as=(.)',
    name: 'ModulePreview',
    component: ModulePreview
  },*/
  {
    path: "/organizations",
    name: "Organizations",
    component: Organizations,
  },
  {
    path: "/orgs/:id",
    name: "Organization",
    component: Organization,
  },
  {
    path: "/orgs/:id/preview",
    name: "OrganizationPreview",
    component: OrganizationPreview,
  },
  {
    path: "/organization-create/:id?",
    name: "OrganizationForm",
    component: OrganizationForm,
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources,
  },
  {
    path: "/resources/:id",
    name: "Resource",
    component: Resource,
  },
  {
    path: "/resources/:id/preview",
    name: "ResourcePreview",
    component: ResourcePreview,
  },
  {
    path: "/resource-create/:id?",
    name: "ResourceForm",
    component: ResourceForm,
  },
  {
    path: "/program-create/:id?",
    name: "ProgramForm",
    component: ProgramForm,
  },
  {
    path: "/programs",
    name: "Programs",
    component: Programs,
  },
  {
    path: "/programs/:id",
    name: "Program",
    component: Program,
  },
  {
    path: "/programs/:id/preview",
    name: "ProgramPreview",
    component: ProgramPreview,
  },
  {
    path: "/evaluation-create/:id?",
    name: "EvaluationForm",
    component: EvaluationForm,
  },
  {
    path: "/evaluations",
    name: "Evaluations",
    component: Evaluations,
  },
  {
    path: "/evaluations/:id",
    name: "Evaluation",
    component: Evaluation,
  },
  {
    path: "/evaluations/:id/preview",
    name: "EvaluationPreview",
    component: EvaluationPreview,
  },
  {
    path: "/webhook-create/:id?",
    name: "WebHookForm",
    component: WebHookForm,
  },
  {
    path: "/webhooks",
    name: "WebHooks",
    component: WebHooks,
  },
  {
    path: "/webhooks/:id",
    name: "WebHook",
    component: WebHook,
  },
  {
    path: "/start-evaluation/:id",
    name: "StartEvaluation",
    component: StartEvaluation,
  },
  {
    path: "/my-modules",
    name: "MyModules",
    component: MyModules,
  },
  {
    path: "/my-account",
    name: "MyAccount",
    component: MyAccount,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFoundComponent,
    name: "NotFound",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
