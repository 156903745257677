
import {defineComponent} from "vue";

export default defineComponent({
  name: 'FormError',
  props: {
    message: {
      type: String,
      require: true,
      default: () => ""
    }
  },
})

