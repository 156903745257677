
import { defineComponent, ref } from "vue";
import { getItemList, searchItems } from "@/services/httpService";
import { useStore } from "@/store";
// import SearchForm from "@/components/SearchForm.vue";
import { organizationsNames } from "@/services/utils/cleanData";
import { url } from "inspector";
import { param } from "jquery";

export default defineComponent({
  name: "Resources",
  components: {
    // SearchForm
  },
  data() {
    const resources: unknown[] = [];
    const orgs: unknown[] = [];
    const orgsRelated: unknown[] = [];
    return { resources, orgs, orgsRelated };
  },
  setup() {
    const store = ref<unknown>(useStore());
    return { store };
  },
  async mounted() {
    const data = await getItemList("resources");
    //const orgs = await getItemList('organizations');
    this.resources = data.resources;
    this.orgs = data.organizations;
    this.orgsRelated = data.organizationsRelatedResources;
  },
  methods: {
    organizationsNames(id: string) {
      // @todo fix on!!! error if no orgs
      return organizationsNames(this.orgs, this.orgsRelated, id);
    },
    getOrgName(orgId: string) {
      // eslint-disable-next-line
      const foundOrg: any = this.orgs.filter((org: any) => {
        return org.id === orgId;
      });
      if (foundOrg[0]) return foundOrg[0].name;
    },
    searchOrganizations(term: string) {
      console.log(term);
    },
    async searchTermSubmit(term: string) {
      const data = await searchItems("resources", `${term}`);
      this.resources = data;
    },
  },
});
