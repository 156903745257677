import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseModalTrigger = _resolveComponent("BaseModalTrigger")!
  const _component_manage_resources = _resolveComponent("manage-resources")!
  const _component_BaseModalView = _resolveComponent("BaseModalView")!
  const _component_manage_evaluators = _resolveComponent("manage-evaluators")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseModalTrigger, {
      "modal-id": "manageResource",
      "open-btn-text": "Manage Resources",
      onOpenModal: _cache[0] || (_cache[0] = () => _ctx.loadModalData('resources')),
      "is-button": true
    }),
    _createVNode(_component_BaseModalView, {
      "modal-id": "manageResource",
      "modal-title": "Manage Resources Below.",
      onCloseModal: _ctx.closeManageResourceModal,
      onSaveModal: _ctx.saveManageResourceModal
    }, {
      default: _withCtx(() => [
        (_ctx.viewName === 'resources')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_manage_resources, {
                module: this.module,
                onPopupDetail: this.popUpDetails,
                onPopupInputData: this.popUpInPutData
              }, null, 8, ["module", "onPopupDetail", "onPopupInputData"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onCloseModal", "onSaveModal"]),
    _createVNode(_component_BaseModal, {
      "modal-id": "manageEvaluators",
      "modal-title": "Manage Evaluators",
      "close-btn-text": "Cancel",
      "is-button": true,
      "save-btn-text": "Save Changes",
      "open-btn-text": "Manage Evaluators",
      "modal-data": this.module,
      onOpenModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadModalData('evaluators')))
    }, {
      default: _withCtx(() => [
        (_ctx.viewName === 'evaluators')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_manage_evaluators, {
                "parent-item": this.module,
                onPopupDetail: this.popUpDetails,
                onPopupInputData: this.popUpInPutData
              }, null, 8, ["parent-item", "onPopupDetail", "onPopupInputData"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modal-data"])
  ], 64))
}