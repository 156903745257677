import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-bs-target"]
const _hoisted_2 = ["data-bs-target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isButton)
    ? (_openBlock(), _createElementBlock("span", _mergeProps({
        key: 0,
        role: "button",
        class: "btn btn-primary btn-lg w-100 m-1",
        "data-bs-toggle": "modal",
        "data-bs-target": '#' + _ctx.modalId
      }, {
      ..._ctx.$attrs,
      onClick: _ctx.triggerOpenModal
        }), _toDisplayString(_ctx.openBtnText), 17, _hoisted_1))
    : (_openBlock(), _createElementBlock("span", _mergeProps({
        key: 1,
        role: "button",
        class: "text-black",
        "data-bs-toggle": "modal",
        "data-bs-target": '#' + _ctx.modalId
      }, {
      ..._ctx.$attrs,
      onClick: _ctx.triggerOpenModal
        }), _toDisplayString(_ctx.openBtnText), 17, _hoisted_2))
}