
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getItem } from "@/services/httpService";
import { formatDate } from "@/services/utils/formatDateString";
import { trimBodyCharacters } from "@/services/utils/cleanData";
import { markdownToHtml } from "@/services/utils/cleanData";
import BaseOrderedList from "@/components/BaseOrderedList.vue";
import HeadingRow from "@/components/HeadingRow.vue";
import ContentRow from "@/components/ContentRow.vue";

export default defineComponent({
  name: "Resource",
  components: { BaseOrderedList, HeadingRow, ContentRow },
  data() {
    const resource: unknown = undefined;
    const {
      params: { id },
    } = useRoute();
    return { resource, id };
  },
  async mounted() {
    const data = await getItem("resources", `${this.id}`);
    if (!data.error) {
      data.data.created = formatDate(data.data.created);
      data.data.updated = formatDate(data.data.updated);
      this.resource = data;
    }

    // TODO: if data is undefined set error!
  },
  methods: {
    trimBodyCharacters(text: string) {
      return trimBodyCharacters(text);
    },
    resourceMdToHtml(text: string) {
      return markdownToHtml(text);
    },
  },
});
