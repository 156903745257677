
import {defineComponent} from "vue";

/**
 * Re-usable BaseInput
 */
export default defineComponent({
  name: 'BaseInput',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String,
      default: ""
    },
    modelValue: {
      type: [String, Number],
      default: "",
    }
  }
})

