
import {defineComponent} from "vue";
import {registerUserRequest, userLoginRequest} from "@/services/httpService";


export default defineComponent({
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
    }
  },
  methods: {
    async onSubmit(e: any) {

      if (!this.email) {
        return
        //@todo add validation errors
      }

      /**
       * @todo check if user exists maybe.
       */
      /*
      const reset = await userResetPassword(this.email);
      this.email = "";
      this.$router.push('/');*/
    }
  }
})

