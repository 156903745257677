
import {defineComponent} from "vue";
import {Modal} from "bootstrap";

/**
 * This modal view is used in conjunction with, BaseModalTrigger,
 * they are supposed to work together.
 *
 * Reasons: A page with multiple modals, we only need each trigger
 * for each intended modal, and a single BaseModalView component, to serve
 * all the triggers on that page.
 */
export default defineComponent({
  name: "BaseModalView",
  emits: ["close-modal", "save-modal"],
  props: {
    // @todo remove commented props, not required
    // isNested: {
    //   type: Boolean,
    //   default: false
    // },
    // nestIds: {
    //   type: Array,
    //   require: false,
    //   description: "Array of Ids, inorder of depth, from the top most parent," +
    //       "to the inner most child in the nest."
    // },
    modalId: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      default: "Title"
    },
    closeBtnText: {
      type: String,
      default: "Close"
    },
    saveBtnText: {
      type: String,
      default: "Save changes"
    },
    modalSize: {
      type: String,
      default: "modal-dialog-scrollable"
    }
  },
  setup(props: any, context: any) {

    const hideModal = () => {
      const modal: any = {}
      modal.value = document.getElementById(props.modalId)
      modal.value = Modal.getOrCreateInstance(modal.value)
      //modal.value.toggle()
      modal.value.hide()

      // Else loop through and close all outside div
      // or add listeners @todo not needed, nestedIds props tobe removed.

    }

    const close = () => {
      context.emit('close-modal', props.modalData)
      hideModal()

    }

    const save = () => {
      context.emit('save-modal', props.modalData)
      hideModal()

    }

    return {close, save}
  }
})

