
import {defineComponent} from "vue";
import {getCompetenciesResource, saveManyToManyFront} from "@/services/httpService";
import HeadingRow from "@/components/HeadingRow.vue";
import {trimBodyCharacters} from "@/services/utils/cleanData";
import ManyToManyFront from "@/types/ManyToManyFront";
import {getSelectedIds} from "@/services/utils/cleanData";
import BaseModalTrigger from "@/components/BaseModalTrigger.vue";
import BaseModalView from "@/components/BaseModalView.vue";
import MultiSelect from "@/components/MultiSelect.vue";
import BaseInput from "@/components/BaseInput.vue";
import {difference, intersection} from "lodash";

/**
 * @todo remove un-used methods from previous.
 */
export default defineComponent({
  name: 'ManageResources',
  components: {
    HeadingRow,
    BaseModalTrigger,
    BaseModalView,
    MultiSelect,
    BaseInput,
    // BaseModal,
    // ManageCompetencyResourceModalSlot
  },
  props:{
    module: {
      required: true,
      type: Object
    }
  },
  emits: ["popup-detail", "popup-input-data"],
  data() {
    const competenciesResources : any = {
      competencies: [],
      resources: [],
    };
    /**
     * @todo remove un-used
     * @todo rename values so they don't
     * conflict with incoming request data object
     * properties and avoid some confusions.
     */
    const resources: any[] = [];
    const selected: any[] = [];
    const modalCompetencyId = ""; // used in the deepest popup modal
    const modalCompetencyResources: any[] = []; // used in the deepest popup modal
    const modalCompetencySelected: any[] = []; // used in the deepest popup modal
    const loadModalCompetency = false; // used in the deepest popup modal
    const resourceFormData: { resources: any[]} = { resources: []};
    const selectedCompetencyResources: any[] = [];
    const selectedCompetencies: any[] = []
    const selectedCompetency = ""
    const loading = false;
    return {
      competenciesResources,
      loadModalCompetency,
      selected, loading,
      resources,
      modalCompetencyId,
      modalCompetencyResources,
      modalCompetencySelected,
      resourceFormData,
      selectedCompetencyResources,
      selectedCompetencies,
      selectedCompetency}
  },
  async mounted() {

    await this.loadComponentData()

  },
  methods: {
    async loadComponentData() {
      // relatedOrganizations
      const rO: string = getSelectedIds(this.module.orgs)

      // relatedCompetencies
      const rC: string = getSelectedIds(this.module.competencies)
      const id: string = this.module.id
      const endpoint = `module-competencies-resources/${id}?organizations=${rO}&competencies=${rC}`
      this.loading = true
      const data = await
          getCompetenciesResource(endpoint);
      this.loading = false
      /**
       * data object contains
       * data[competenciesRelated] = competencies related to this module
       * data[resourcesRelatedToCompetencies][competencyResources] =
       * An object with key/properties of competencyId and a value of an
       * array of resourcesId related to that competencyId.
       * data[resourcesRelatedToCompetencies][resourcesRelated] =
       * Array of resources Objects related to these competencies
       * above, ie competencies related.
       */

      this.competenciesResources = data
      //this.selected = data.resourcesRelatedToModule.map((item: any) => item.id)
      this.selected = data.resourcesSelected
      // console.log(data.resourcesRelatedToCompetencies.resourceOrganizations)
      // remove resouces from a different or, leave public and those that belongs to
      // this modules organization. @todo

      //this.selected = this.module.data.resources

      /**
       * We need to emit to the parent component,
       * some data, including, the endpoint to send the update
       * request to, and the item that owns the data
       * we're updating here.
       */
      const popUpDetails: ManyToManyFront = {
        collectionDocIdSuffix: "",
        collectionDocIdPrefix: this.module.id,
        collectionPrefixName: "Modules",
        collectionSuffixName: "Resources",
        // we might need prevSelections too @todo
        // itemsOptions: data?.resourcesRelatedToCompetencies?.resourcesRelated
        itemsOptions: this.selected,
        allItems: data?.resources,
      };
      this.$emit('popup-detail', popUpDetails)
    },
    /**
     * Returns true if all resources in
     * a competency are checked
     * @param competencyId
     */
    isCompetencyChecked(competencyId: string){
      const resourcesRelated = this.resourcesRelatedToCompetencyId(competencyId)
      ?.map((item) => item.id)

      if (resourcesRelated.length &&
          (resourcesRelated.length === intersection(resourcesRelated, this.selected).length)){
        return true
      }
      return false
    },
    /**
     * Selects all resources belonging to a
     * competency, also propagates the reactivity to
     * other similar resources for prev workflow.
     * @param e
     */
    competencyChecked(e: any) {
      const {value} = e.target

      const resources = this.resourcesRelatedToCompetencyId(value)
      resources.forEach((resourceId) => {
        this.selectResources(e, resourceId)
      })
    },
    /**
     * Creates new resources from newTag string, in
     * resources search and add.
     * @param{string} newTag
     */
    async createCompetencyResource(newTag: string) {
      const resourceTitles: string[] = newTag.split("\n")
      const resourceRelations = {
        competencyId: this.modalCompetencyId,
        moduleId: this.module.id
      }
      const items =  {
        items: JSON.stringify([resourceTitles, resourceRelations])
      }

      const savedResources: any = await saveManyToManyFront({
        endpoint:  'resources-create',
        type: 'create',
        items: items
      });

      this.modalCompetencySelected = [...savedResources, ...this.modalCompetencySelected]
      this.modalCompetencyResources = [...this.modalCompetencyResources]

    },
    async openResourceSearchAdd(data: any) {
      const relatedOrganizations: string = getSelectedIds(this.module.orgs)
      const uri = `module-competencies-resources-modal/${this.module.id}?competency-id=${data.id}&related-organizations=${relatedOrganizations}`
      const responseData: any = await
      getCompetenciesResource(
          `${uri}`);
      if (!responseData.error) {
        const {resources, relatedResources} = responseData

        /**
         * Show resources related to this module in regards to this competency,
         * and it's attached resources ie public and/or those related to
         * this.module.orgs @todo
         */

        this.modalCompetencyId = data.id
        this.modalCompetencySelected = [...relatedResources]
        this.modalCompetencyResources = [...resources]
      }

    },

    trimBodyCharacters(text: string) {
      return trimBodyCharacters(text)
    },

    resourcesRelatedToCompetencyId(competencyId: string) {
      /**
       * we get the resource ids related to this competencyId
       * from competencyResources
       */
      const competencyIdResources = this.competenciesResources
          .competenciesResources[competencyId];

      if (!competencyIdResources?.length) return []

      /**
       * with the resources ids, we get the resources now
       * (id, name, body/description) for use as the checklist
       * items.
       */
      const resourcesForThisCompetency: any[] = []
      const resourcesAlreadySelected: any[] = [...this.competenciesResources.resourcesSelected]
      this.competenciesResources.
      resources.map((resource: any) => {
        if (competencyIdResources.includes(resource.id)) {
          // for simplicity, update resources that
          // are already checked here too.
          // @todo here now
          if (resourcesAlreadySelected.includes(resource.id)) resource.isSelected = true
          //console.log(resourcesRelatedToModule)
          resourcesForThisCompetency.push(resource)
        }
      });

      return resourcesForThisCompetency;
    },

    /**
     * Tracking the competencyId associated with
     * each selected resource, the backend
     * needs to relate the Resource to the Competency
     * and the Module
     * @param{any} resource
     * @param{any} event
     */
    selectResources(event: any, resource: any) {
      /**
       * const {
        resourcesSelected, competenciesResources, resources
        } = this.competenciesResources
       */
      // reused in competencyChecked that passes the resource as an
      // id
      const resourceId = resource.id ? resource.id : resource
      if (event.target.checked) {
        this.selected = [...this.selected, resourceId]

      } else {
        this.selected = [...this.selected].filter((id) => id !== resourceId)

      }

      /**
       * @Dev notes
       * Do we need a relation for Competency-resource-module?
       * Because a resource can belong to many Competencies,
       * if we remove a resource to a module, do we remove the resource
       * in all other related competencies, and if we add that, do we
       * add the resource among all the competencies that belongs to the module.
       * How do we determine how one resource belongs to a module via a competency,
       * and not to the module via another competency, yet the competencies belong to
       * that module, and the same resources belong the to same competencies above.
       */

      const newResources: any[] = []
      const competencies: any = this.competenciesResources.competencies
      const competenciesResources: any = this.competenciesResources.competenciesResources

          this.competenciesResources.resources.forEach((item: any) => {

            const newItem = {...item}
            if (newItem.id === resourceId) newItem.isSelected = event.target.checked

            newResources.push(newItem)
          })

      // @todo better with this reactivity
      // @todo data should be loaded when launch modal btn is
      // clicked not on this component mount, @todo clean!

      this.competenciesResources = {
        resources: newResources,
        competencies,
        resourcesSelected: this.selected,
        competenciesResources
      }
      // updates selected items on parent ie this.itemsSelected
      // This might not be need when this.selected is v-modelled
      // and the initial values supplied on modalShow click.
      this.$emit('popup-input-data', this.selected);

    },

    popUpDetails(detail: any) {
      // this.popUpDetailsObj = detail;
    },
    popUpInPutData(data: any) {
      // this.itemsSelected = data;
    },
    async saveChildModal() {
      const selectedResourcesIds = getSelectedIds(this.modalCompetencySelected);


      const resourceRelations = {
        competencyId: this.modalCompetencyId,
        moduleId: this.module.id
      }

      const items =  {
        items: JSON.stringify([selectedResourcesIds, resourceRelations])
      }

      const savedResources: any = await saveManyToManyFront({
        endpoint:  'resources-create',
        type: 'update',
        items: items
      });

      // We save the resources in relation to this competency
      // and module, but leave the resources as public for now
      // @todo confirm if all added resource via this modal, should
      // contain all the organizations this module has.

      //Finally  reset modal data
      this.modalCompetencyId = ""
      this.modalCompetencySelected = []
      this.modalCompetencyResources = []

      // reload instead @todo
      await this.loadComponentData()
    },
    async closeChildModal() {

      //Finally  reset modal data
      this.modalCompetencyId = ""
      this.modalCompetencySelected = []
      this.modalCompetencyResources = []

      // // reload instead @todo
      await this.loadComponentData()

    },
  },

})

