
import { defineComponent, ref } from "vue";
import {
  getItem,
  getItemList,
  saveItem,
  searchItems,
} from "@/services/httpService";
import { useStore } from "@/store";
// import SearchForm from "@/components/SearchForm.vue";
import RenderImage from "@/components/RenderImage.vue";
import DuplicateModuleModalSlot from "@/components/DuplicateModuleModalSlot.vue";
import { formatDate } from "@/services/utils/formatDateString";
import { getSelectedIds } from "@/services/utils/cleanData";
import BaseModalTrigger from "@/components/BaseModalTrigger.vue";
import BaseModalView from "@/components/BaseModalView.vue";
// import Module from "@/types/Module";

export default defineComponent({
  name: "Modules",
  components: {
    // SearchForm,
    RenderImage,
    BaseModalTrigger,
    BaseModalView,
    DuplicateModuleModalSlot,
    // DuplicateModule,
  },
  data() {
    // eslint-disable-next-line
    const modules: any[] = [];
    // eslint-disable-next-line
    const mods: any[] = [];
    // eslint-disable-next-line
    const duplicateModule: any = {};
    return { modules, mods, duplicateModule };
  },
  setup() {
    const store = ref<unknown>(useStore());
    return { store };
  },
  async mounted() {
    const data = await getItemList("modules");
    // eslint-disable-next-line
    data.sort((a: any, b: any) => (a.data.name > b.data.name ? 1 : -1));
    this.modules = [...data];
  },

  methods: {
    closeModal() {
      this.duplicateModule = {};
    },
    /**
     * Saves the duplicate module
     * and redirects to the edit screen.
     */
    async saveModal() {
      const {
        data,
        relatedOrganizations,
        relatedCompetencies,
        resourcesRelatedToModule,
      } = this.duplicateModule;
      // eslint-disable-next-line
      const duplicateModule: any = {
        name: data.name,
        description: data.description,
        tags: data.tags,
        orgs: getSelectedIds(relatedOrganizations),
        competencies: getSelectedIds(relatedCompetencies),
        resources: getSelectedIds(resourcesRelatedToModule.resourcesRelated),
        expiration: data.expiration,
        imageUrl: data.imageUrl,
      };
      // eslint-disable-next-line
      const saved: any = await saveItem({
        endpoint: "module-create",
        type: "create",
        item: duplicateModule,
      });

      if (!saved.error) {
        const editUrl = `/module-create/${saved.docId}?action=edit`;
        return await this.$router.push(editUrl);
      }

      this.duplicateModule = {};
    },
    // eslint-disable-next-line
    async openModal(module: any) {
      const data = await getItem("modules", `${module.id}`);
      data.data.name = "Copy of - " + data.data.name;
      data.data.created = formatDate(data.data.created);
      data.data.updated = formatDate(data.data.updated);
      data.data.expiration = formatDate(data.data.expiration);
      this.duplicateModule = data;
    },
    // searchOrganizations(term: string) {
    //   console.log(term)
    // },
    async searchTermSubmit(term: string) {
      //const data = await getItemList(`modules?search=${term}`);
      const data = await searchItems("modules", `${term}`);
      //console.log(data)
      this.modules = data;
      /*const message = `Search results for term: ${term}, yielded ${data.length} items. `
      await this.store.dispatch('SET_MESSAGE',message)*/
    },
  },
});
