import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["href"]
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.items?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.listTitle)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.listTitle + ": "), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.items[0].externalUrl)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item[_ctx.idKey],
                  type: ""
                }, [
                  _createElementVNode("a", {
                    href: item.externalUrl,
                    class: "link-primary",
                    target: "_blank"
                  }, _toDisplayString(item[_ctx.displayKey]), 9, _hoisted_4)
                ]))
              }), 128))
            ]))
          : (!_ctx.items[0].linkTo)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item[_ctx.idKey],
                    type: ""
                  }, _toDisplayString(item[_ctx.displayKey]), 1))
                }), 128))
              ]))
            : (_ctx.items[0].linkTo)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: item[_ctx.idKey],
                      type: ""
                    }, [
                      _createVNode(_component_router_link, {
                        to: { name: _ctx.items.linkTo, params: { id: item[_ctx.idKey] } }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item[_ctx.displayKey]), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}