

import {defineComponent, onMounted, ref} from "vue";
import {Modal} from "bootstrap";
/**
 * When multiple nested modal are used,
 * there's an issue with bootstrap
 * classes being applied to one div
 * and another div later (timely not in sync
 * with custom dom modifiers too)
 * we switch to a custom vue modal at this point
 *
 * @todo improvised but needs more experimenting
 * on mobile device.
 *
 */
/**
 * Re-usable modal component
 * 3 events to utilize on parent;
 * open-modal, close-modal, save-modal
 * as their names suggests
 */
export default defineComponent({
  name: "BaseModal",
  props: {
    modalId: {
      required: true
    },
    modalTitle: {
      type: String,
      default: "Title"
    },
    tabIndex: {
      type: String,
      default: "-1"
    },
    modalData: {
      required: true
    },
    openBtnText: {
      type: String,
      default: "..."
    },
    isButton: {
      type: Boolean,
      default: false
    },
    closeBtnText: {
      type: String,
      default: "Close"
    },
    saveBtnText: {
      type: String,
      default: "Save changes"
    },
  },
  emits: ["open-modal", "close-modal", "save-modal"],
  setup(props: any, context: any) {

    // onMounted(() => {
    //   // registers events on modals on the page
    //   const modals: any = document.getElementById(props?.modalId)
    //
    //   // Add events to modals
    //   modals?.addEventListener("show.bs.modal", () => {
    //   })
    // });

    const open = (data: any = {}) => {
      const modal: any = {}
      modal.value = document.getElementById(props?.modalId)
      modal.value = new Modal(modal.value)
      modal.value.show()
      context.emit('open-modal', data)

      /**
       * When multiple nested modal are used,
       * there's an issue with bootstrap
       * classes being applied to one div
       * and another div later
       *
       * nb, works now but needs experimenting!
        */

    }

    const close = () => {
      context.emit('close-modal', props.modalData)
    }

    const save = () => {
      context.emit('save-modal', props.modalData)
    }

    return {close, save, open}
  }
})

