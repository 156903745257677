
import {defineComponent, PropType, ref} from "vue";
import Resource from "@/types/Resource";
import {getItem, getItemList, saveItem} from "@/services/httpService";
import {useRoute} from "vue-router";
import GoBack from "@/components/GoBack.vue";
import EntityFlag from "@/components/EntityFlag.vue";
import VueMultiselect from "vue-multiselect";
import {getSelectedIds} from "@/services/utils/cleanData";
import BaseInput from "@/components/BaseInput.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import axios from "axios";
import cheerio from "cheerio";


export default defineComponent({
  name: 'ResourceForm',
  components: {
    GoBack,
    EntityFlag,
    VueMultiselect,
    BaseInput,
    BaseTextArea,
  },
  data() {
    const organizations: any[] = []
    const competencies: any[] = []
    const modules: any[] = []
    const resourceOrg = ''
    const resource: Resource = {
      id: '',
      name: '',
      slug: '',
      body: '',
      tags: '',
      orgId: '',
      orgs: [],
      competencies: [],
      modules: [],
      externalUrl: '',
      created: '',
      updated: '',
      archived: false,
      //lastModifiedBy: `${auth.currentUser}`,
    }
    return {resource, organizations, resourceOrg, competencies, modules}
  },
  async mounted() {
    
    const {params: {id}} = useRoute();
    this.resource = {...this.resource, id: id ? `${id}` : '',}

    if(typeof this.$route.query.url === 'string'){
      this.resource.externalUrl = this.$route.query.url
      const url = ("https://cors-anywhere.herokuapp.com/" + this.$route.query.url) //"https://cors-anywhere.herokuapp.com/" + 
      const AxiosInstance = axios.create();

        //web scraping
        AxiosInstance.get(url)
        .then((response)=>{
          const $ = cheerio.load(response.data);
          console.log($);
          const title = $('title');
          this.resource.name= title.text();
          this.resource.body = $('description').text();
        });
        
    }
    else if (this.resource.id) {
      const data = await getItem('resources', `${this.resource.id}`);
      if (!data.data) {
        // @todo set error
        return null
      }
      this.resource.name = data.data.name
      this.resource.slug = data.data.slug
      this.resource.body = data.data.body
      this.resource.tags = data.data.tags
      // this.resource.orgId = data.data.orgId
      this.resource.orgs = data.relatedOrganizations
      this.resource.externalUrl = data.data.externalUrl
      this.resource.competencies = data.relatedCompetencies
      this.resource.modules = data.relatedModules
      this.resource.created = data.data.created
      this.resource.updated = data.data.updated
      this.resource.archived = data.data.archived ? data.data.archived : false
      this.organizations = data.organizations;
      this.competencies = data.competencies;
      this.modules = data.modules;
    }
    else {
      const endpoint = 'organizations?extras-attached=competencies,modules'
      const {organizations, competencies, modules} = await getItemList(endpoint);
      this.organizations = organizations;
      this.competencies = competencies;
      this.modules = modules;
    }

  },
  methods: {
    async onSubmit() {

      const resource: Resource = {
        name: this.resource.name,
        slug: this.resource.slug,
        body: this.resource.body,
        tags: this.resource.tags,
        orgs: getSelectedIds(this.resource.orgs),
        externalUrl: this.resource.externalUrl,
        archived: this.resource.archived,
        competencies: getSelectedIds(this.resource.competencies),
        modules: getSelectedIds(this.resource.modules),
      }

      const saved = await saveItem({
        endpoint: !this.resource.id ? 'resource-create' : `resources/${this.resource.id}`,
        type: !this.resource.id ? 'create' : 'update',
        item: resource
      });

      if (saved.message) {
        this.resource.name = ''
        this.resource.slug = ''
        this.resource.body = ''
        this.resource.tags = ''
        this.resource.orgs = []
        this.resource.competencies = []
        this.resource.modules = []
        this.resource.externalUrl = ''
        this.resource.archived = false
        await this.$router.push('/resources')
        return
      }
    },
    changeOrganizationId (event: any) {
      this.resource.orgId = event.target.options[event.target.options.selectedIndex].value
      this.resourceOrg = event.target.options[event.target.options.selectedIndex].text
    },
  }
})
