import firebase from 'firebase/compat/app';
import {initializeApp, getApps} from "firebase/app"
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
/*
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/functions';

import { useStore, store } from '@/store';

 */
import appConfig from "@/services/config";
if (!getApps.length) {
    initializeApp(appConfig.firebase);
}
export const auth = getAuth();
export const googleProvider = new GoogleAuthProvider()
//export const functions = firebase.functions();
