
import { defineComponent } from "vue";
import { getItemList } from "@/services/httpService";
import { organizationsNames } from "@/services/utils/cleanData";

export default defineComponent({
  name: "WebHooks",
  data() {
    const webhooks: unknown[] = [];
    const organizations: unknown[] = [];
    const organizationsRelatedToWebhooks: unknown[] = [];
    return { webhooks, organizations, organizationsRelatedToWebhooks };
  },
  async mounted() {
    /**
     * @todo optimize load time
     */
    const data = await getItemList("webhooks");
    this.webhooks = data.webhookList;
    this.organizations = data.organizations;
    this.organizationsRelatedToWebhooks = data.organizationsRelatedToWebhooks;
  },
  methods: {
    organizationsNames(id: string) {
      // @todo fix on!!! error if no orgs
      return organizationsNames(
        this.organizations,
        this.organizationsRelatedToWebhooks,
        id
      );
    },
    getOrgName(orgId: string) {
      // eslint-disable-next-line
      const foundOrg: any = this.organizations.filter((org: any) => {
        return org.id === orgId;
      });
      if (foundOrg[0]) return foundOrg[0].name;
    },
  },
});
