import { InjectionKey } from 'vue'
import {
    createStore,
    createLogger,
    useStore as baseUseStore,Store } from 'vuex'
import {auth, googleProvider} from "@/services/fireBase";
import {
    signInWithPopup,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
} from "firebase/auth";

const debug = process.env.NODE_ENV !== 'production'

// define your typings for the store state
export interface State {
    loadingStatus: string,
    user: any,
    authIsReady: any,
    roles: any,
    error: any,
    message: any,
}
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
    state: {
        loadingStatus: '',
        user: null,
        authIsReady: false,
        roles: {},
        error: '',
        message: '',
    },
    getters: {
        user: (state) => {
            return state.user;
        },
        userRoles: (state) => {
            return state.roles
        },
        authIsReady: (state) => {
            return state.authIsReady
        },
        loadingStatus: (state) => {
            return state.loadingStatus
        },

        error: (state) => {
            return state.error
        },

        message: (state) => {
            return state.message
        }
    },
    actions: {
        user({commit}, user) {
            commit('user', user)
        },
        // payload might not be useful
        // uses signInWithPopup(auth, googleProvider)
        // see mutations.SIGN_UP
        async signIn({commit}, payload) {
            let res: any = {};
            if (payload.email) {
                res = await signInWithEmailAndPassword(auth, payload.email, payload.password)
            } else {
                res = await signInWithPopup(auth, googleProvider)
            }

            if (res) {
                const idTokenResult = await res.user?.getIdTokenResult()
                if (idTokenResult) store.commit("SET_USER_ROLES", idTokenResult?.claims)
                commit("USER_LOGIN", res.user)

            } else {
                throw new Error("Could not login!")
            }

            // Router.push('/');
        },
        async signOut({commit}) {
            await signOut(auth)
            store.commit("SET_USER_ROLES", {})
            commit("USER_LOGIN", null)
        },
        SET_LOADING_STATUS({commit}, status) {
            commit('SET_LOADING_STATUS', status)
        },
        SET_ERROR({commit}, {error}) {
            // TODO we should also reset message if any for the error
            commit('SET_LOADING_STATUS', 'loaded')
            commit('SET_ERROR', error)
        },
        SET_MESSAGE({commit}, message) {
            // TODO we should also reset error if any for the message
            commit('SET_LOADING_STATUS', 'loaded')
            commit('SET_MESSAGE', message)
        }
    },
    mutations: {
        user(state, user) {
            state.user = user;
        },
        USER_LOGIN(state, payload){
            state.user = payload
        },
        SET_USER_ROLES(state, payload){
            state.roles = payload
        },
        SET_AUTH_IS_READY(state, payload){
            state.authIsReady = payload
        },
        SET_LOADING_STATUS(state, status){
            state.loadingStatus = status
        },
        SET_ERROR(state, error){
            state.error = error
        },
        SET_MESSAGE(state, message){
            state.message = message
        }
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
});

export function useStore () {
    return baseUseStore(key)
}

const unsub = onAuthStateChanged(auth, async (user) => {
    const idTokenResult = await user?.getIdTokenResult(true)
    if (idTokenResult) store.commit("SET_USER_ROLES", idTokenResult?.claims)
    store.commit("SET_AUTH_IS_READY", true)
    store.commit("USER_LOGIN", user)
    unsub()
})