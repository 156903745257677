
import { computed, defineComponent, onMounted } from "vue";
import Header from "@/components/Header.vue";
import Status from "@/components/Status.vue";
import Footer from "@/components/Footer.vue";
import { useStore } from "@/store";
export default defineComponent({
  name: "App",
  components: {
    Header,
    Status,
    Footer,
  },
  setup() {
    const store = useStore();
    const authIsReady = computed(() => {
      return store.state.authIsReady;
    });

    const loadingStatus = computed(() => {
      return store.state.loadingStatus;
    });

    onMounted(() => {
      // document.body.classList.add('bg-light')
      document.title = "Abilibase";
      document.body.setAttribute("ref", "body");
    });

    return { store, authIsReady, loadingStatus };
  },
  created() {
    // const res = await axios.get('user')
    // use token to get user or token as user!! @todo
    // get token instead @todo
    // TODO: remove this.user just for testing admin routes during dev.
    // this.store.dispatch('user', localStorage.getItem('user_token'))
    // console.log(this.$attrs);
  },
});
