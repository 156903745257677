
const appConfig = {
    firebase: {
        apiKey: process.env.VUE_APP_apiKey,
        authDomain: process.env.VUE_APP_authDomain,
        // databaseURL: process.env.VUE_APP_databaseURL,
        projectId: process.env.VUE_APP_projectId,
        //storageBucket: "",
        //messagingSenderId: "",
        appId: process.env.VUE_APP_appId,
        measurementId: process.env.VUE_APP_measurementId
    },
    meiliSearch: {}
}

export default appConfig