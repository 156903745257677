
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getPreviewItem } from "@/services/httpService";

export default defineComponent({
  name: "OrganizationPreview",
  data() {
    const organization: unknown = undefined;
    const {
      params: { id },
    } = useRoute();
    return { organization, id };
  },
  async mounted() {
    const data = await getPreviewItem("organizations", `${this.id}/preview`);
    this.organization = data;
  },
});
