
import {defineComponent} from "vue";

export default defineComponent({
  name: 'GoBack',
  props: ['redirectTo'],
  methods: {
    goBack() {
      //this.$router.back()
      this.$router.push(this.redirectTo)
    }
  }

})

