
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getItem, saveItem } from "@/services/httpService";
import Evaluation from "@/types/Evaluation";
import { getSelectedIds, markdownToHtml } from "@/services/utils/cleanData";
import ContentRow from "@/components/ContentRow.vue";
import BaseOrderedList from "@/components/BaseOrderedList.vue";
import HeadingRow from "@/components/HeadingRow.vue";
import RenderMd from "@/components/RenderMd.vue";

export default defineComponent({
  name: "Evaluation",
  components: {
    ContentRow,
    HeadingRow,
    BaseOrderedList,
    RenderMd,
  },
  data() {
    // eslint-disable-next-line
    const evaluation: any = undefined;
    const {
      params: { id },
    } = useRoute();
    return { evaluation, id };
  },
  async mounted() {
    const data = await getItem("evaluations", `${this.id}`);
    // data.data.created = formatDate(data.data.created);
    this.evaluation = data;
  },
  methods: {
    // eslint-disable-next-line
    getOrg(Org: any) {
      return JSON.parse(Org);
    },
    // eslint-disable-next-line
    getModules(modules: any) {
      return JSON.parse(modules);
    },
    evaluationMdToHtml(text: string) {
      return markdownToHtml(text);
    },
    async duplicateEvaluation() {
      console.log(this.evaluation);
      const evaluation: Evaluation = {
        name: `Copy of - ${this.evaluation.data.name}`,
        body: this.evaluation.data.body,
        orgId: this.evaluation.organization?.id,
        modules: getSelectedIds(this.evaluation.relatedModules),
        evaluationUrl: this.evaluation.data.evaluationUrl,
        rubricUrl: this.evaluation.data.rubricUrl,
        archived: false,
      };
      const saved = await saveItem({
        endpoint: "evaluation-create",
        type: "create",
        item: evaluation,
      });
      if (saved.docId) {
        const editUrl = `/evaluation-create/${saved.docId}?action=edit`;
        return await this.$router.push(editUrl);
      }
    },
  },
});
