import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import {store, key } from './store/index';
import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import {marked} from "marked";


const markedMixin = {
    methods: {
        md: function (input: string) {
            return marked(input);
        }
    }
}
// 5. Create and mount the root instance.
const app = createApp(App, {
    mixins: [markedMixin]
})

/**
 * router.afterEach clears the
 * status message (error or message)
 * after each navigation.
 */
router.afterEach((to, from) => {
    setTimeout(async () => {
        await store.dispatch('SET_ERROR', "")
        await store.dispatch('SET_MESSAGE', "")
    }, 10000);
})

app.use(store, key);
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router);


app.mount('#app');

// so Cypress can access our vue app
if ((<any>window)?.Cypress) {
    (<any>window).__app__ = app
}
