
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getItem } from "@/services/httpService";
import { formatDate } from "@/services/utils/formatDateString";
import RenderMd from "@/components/RenderMd.vue";

/**
 * Display module links instead of names, or even module content
 * @todo
 */

export default defineComponent({
  name: "EvaluationPreview",
  components: { RenderMd },
  data() {
    const evaluation: unknown = undefined;
    const organization: unknown = {};
    const modules: unknown[] = [];
    const {
      params: { id },
    } = useRoute();
    return { evaluation, id, organization, modules };
  },
  async mounted() {
    const data = await getItem("evaluations", `${this.id}/preview`);
    data.data.created = formatDate(data.data.created);
    this.evaluation = data;
    this.organization = data.organization;
    this.modules = data.modules;
  },
});
