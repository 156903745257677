
import { defineComponent, ref } from "vue";
import { getItemList, searchItems } from "@/services/httpService";
import { useStore } from "@/store";
// import SearchForm from "@/components/SearchForm.vue";

export default defineComponent({
  name: "Evaluations",
  components: {
    // SearchForm
  },
  data() {
    const evaluations: unknown[] = [];
    const orgs: unknown[] = [];
    return { evaluations, orgs };
  },
  setup() {
    const store = ref<unknown>(useStore());
    return { store };
  },
  async mounted() {
    /**
     * @todo optimize
     */
    const data = await getItemList("evaluations");
    this.evaluations = data.evaluations;
    this.orgs = data.organizations;
  },
  methods: {
    getOrgName(orgId: unknown) {
      // eslint-disable-next-line
      const foundOrg: any = this.orgs.filter((org: any) => {
        return org.id === orgId;
      });
      if (foundOrg[0]) return foundOrg[0].name;
    },
    searchOrganizations(term: string) {
      console.log(term);
    },
    async searchTermSubmit(term: string) {
      /*const data = await getItemList(`evaluations?search=${term}`);
      this.evaluations = data;
      const message = `Search results for term: ${term}, yielded ${data.length} items. `
      await this.store.dispatch('SET_MESSAGE',message)*/
      const data = await searchItems("evaluations", `${term}`);
      this.evaluations = data;
    },
  },
});
