
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getItem } from "@/services/httpService";
import HeadingRow from "@/components/HeadingRow.vue";
import ContentRow from "@/components/ContentRow.vue";
import BaseOrderedList from "@/components/BaseOrderedList.vue";

export default defineComponent({
  name: "WebHook",
  components: {
    HeadingRow,
    ContentRow,
    BaseOrderedList,
  },
  data() {
    const webhook: unknown = {};
    const relatedOrganizations: unknown[] = [];

    const {
      params: { id },
    } = useRoute();
    return { webhook, id, relatedOrganizations };
  },
  async mounted() {
    const data = await getItem(
      "webhooks",
      `${this.id}?attached=this-organization`
    );
    this.webhook = data;
  },
});
