
import {defineComponent} from "vue";
import Module from "@/types/Module";
import {
  getItem,
  getItemList,
  saveItem,
  saveManyToManyFront} from "@/services/httpService";
import {useRoute} from "vue-router";
import GoBack from "@/components/GoBack.vue";
import EntityFlag from "@/components/EntityFlag.vue";
import {formatDate} from "@/services/utils/formatDateString";
import VueMultiselect from "vue-multiselect";
import {getSelectedIds} from "@/services/utils/cleanData";
import ManageResourcesEvaluatorsModal from "@/components/ManageResourcesEvaluatorsModal.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import MultiSelect from "@/components/MultiSelect.vue";


export default defineComponent({
  name: 'ModuleForm',
  components: {
    GoBack,
    EntityFlag,
    VueMultiselect,
    ManageResourcesEvaluatorsModal,
    MultiSelect,
    BaseInput,
    BaseTextArea,
    //
  },
  data() {
    const organizations: any[] = []
    const competencies: any[] = []
    const evaluators: any[] = []
    const competencyTerm = ''
    const module: Module = {
      id: '',
      slug: '',
      name: '',
      description: '',
      imageUrl: '',
      tags: '',
      orgs: [],
      competencies: [],
      expiration: '',
      created: '',
      updated: '',
      lastUpdatedBy: '',
      archived: false,
    }
    return {module, organizations, competencies, evaluators, competencyTerm}
  },
  async mounted() {

    const {params: {id}} = useRoute();
    this.module = {...this.module, id :id ? `${id}` : '',};

    if (this.module.id) {
      const data = await getItem('modules', `${this.module.id}`);
      if (!data.data) {
        // @todo set error
        return null
      }

      this.organizations = data.organizations;
      this.competencies = data.competencies;
      this.module = {
        id: data.id,
        slug: data.data.slug,
        name: data.data.name,
        description: data.data.description,
        tags: data.data.tags,
        orgs: data.relatedOrganizations,
        competencies: data.relatedCompetencies,
        imageUrl: data.data.imageUrl,
        expiration: data.data.expiration,
        created: formatDate(data.data.created),
        updated: formatDate(data.data.updated),
        lastUpdatedBy: data.data.lastUpdatedBy,
        archived: data.data.archived,
      };

    }
    else {
      const data = await getItemList('organizations?extras-attached=competencies');
      this.organizations = data.organizations;
      this.competencies = data.competencies;
    }

  },
  methods: {
    async onSubmit() {
      const module: Module = {
        name: this.module.name,
        slug: this.module.slug,
        description: this.module.description,
        tags: this.module.tags,
        orgs: getSelectedIds(this.module.orgs),
        competencies: getSelectedIds(this.module.competencies),
        expiration: this.module.expiration,
        imageUrl: this.module.imageUrl,
        archived: this.module.archived,
      }

      const saved = await saveItem({
        endpoint: !this.module.id ? 'module-create' : `modules/${this.module.id}`,
        type: !this.module.id ? 'create' : 'update',
        item: module
      });

      if (!saved.error) {
        // clear form values
        this.module.name = ''
        this.module.description = ''
        this.module.tags = ''
        this.module.orgs = []
        this.module.competencies = []
        this.module.imageUrl = ''
        this.module.expiration = ''
        this.module.created = ''
        this.module.updated = ''
        this.module.lastUpdatedBy = '' // @todo get from res
        await this.$router.push('/modules')
        return
      } else {
        // @todo add error to state via store
      }
    },
    /**
     * Creates a new competency from search if non-existent.
     * Adds organizations from the module to the,
     * new competency.
     * @param{string} newTag
     */
    async createCompetency(newTag: string) {

      if (!newTag.length) return
      const competenciesBody: string[] = newTag.split("\n")

      const items =  {
        items: JSON.stringify([competenciesBody, JSON.parse(getSelectedIds(this.module.orgs))])
      }

      const savedItems: any = await saveManyToManyFront({
        endpoint:  'competencies-create',
        type: 'create',
        items: items
      });

      if (!savedItems.error) {
        // Having this issue again @todo better
        // https://forum.vuejs.org/t/vue-array-converted-to-proxy-object/113650/4
        const newCompetencyList: any[] = [/*...savedItems, */...JSON.parse(JSON.stringify(this.competencies))]
        const moduleCompetencies: any[] = [...savedItems, ...JSON.parse(JSON.stringify(this.module.competencies))]
        this.competencies = newCompetencyList
        this.module.competencies = moduleCompetencies
        return;
      }

      // @todo handle error(s)
    }
  }
})
