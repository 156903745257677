
import {defineComponent, ref} from "vue";
/**
 * @todo
 * not yet complete, under development and testing, don't use yet
 */
export default defineComponent({
  name: 'AbilibaseMultiSelect',
  data() {

    return {
      focused: false,
      optionsTop: "34px;",
    }
  },
  computed: {
    formattedOptions() {
      let fo = this.options.map((option: any) => {

        let checked = this.value
            .some((v: any) => v[this.valueProperty] === option[this.valueProperty])
        console.log("++++++ checked +++++")
        console.log(checked)
        console.log(this.value)
        console.log(option[this.valueProperty])
        console.log("++++++ checked +++++")

        return {...option, checked: checked}
      });
      return fo
    },
  },
  setup() {
    const parentDiv: any = ref();
    return {parentDiv}
  },
  mounted() {
    // this.alignTopPosition()
  },
  methods: {
    handleClick() {
      this.focused = !this.focused
    },
    preventClose(e: any) {
      e.stopPropagation()
    },
    handleOptionClick(item: any) {
      console.log(item)
    }
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      default:  () => [],
    },
    placeholder: {
      type: String,
      default: "Click to select"
    },
    displayProperty: {
      type: String,
      default: "name",
    },
    uniqueProperty: {
      type: String,
      default: "id",
    },
    valueProperty: {
      type: String,
      default: "value",
    },
  }
})
