
import {defineComponent, ref, computed} from "vue";
// import {auth, googleProvider} from "@/services/fireBase";
// import { signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
export default defineComponent({
  name: 'UserLogin',
  setup() {
    const store = useStore()
    const router = useRouter()
    const email = ref<any>("")
    const password = ref<any>("")
    const onSubmit = async () => {
      if (!email.value || !password.value) {
        return
      }

      try {
        // await signInWithEmailAndPassword(auth, this.email, this.password)
        const creds = {
          email: email.value,
          password: password.value
        }
        await store.dispatch("signIn", creds)

        await router.push('/');
      } catch (e) {
        //this.error = e
        return
      }
    }

    const signInWithGoogleAccount = async () => {
      //await signInWithPopup(auth, googleProvider)
      await store.dispatch("signIn", "")

      await router.push('/');
    }

    return {
      store,
      email,
      password,
      onSubmit,
      signInWithGoogleAccount
    };
  },
})
