
/**
 * Modal to manage evaluators and resources on
 * module's edit page
 */
import {defineComponent} from "vue";
import ManageEvaluators from "@/components/ManageEvaluators.vue";
import ManageResources from "@/components/ManageResources.vue";
import ManyToManyFront from "@/types/ManyToManyFront";
import {updateManyToMany} from "@/services/httpService";
import {getSelectedIds} from "@/services/utils/cleanData";
import BaseModal from "@/components/BaseModal.vue";
import BaseModalTrigger from "@/components/BaseModalTrigger.vue";
import BaseModalView from "@/components/BaseModalView.vue";

export default defineComponent({
  name: 'ManageResourcesEvaluatorsModal',
  components: {
    BaseModal,
    ManageResources,
    ManageEvaluators,
    BaseModalTrigger,
    BaseModalView
  },
  data() {
    const viewName = ""
    const popUpDetailsObj: any = {};
    const itemsSelected: any[] = [];
    return {viewName, popUpDetailsObj, itemsSelected}

  },
  props: {
    buttonText: {
      type: String,
      required: true,
      description: "The button text to use in the popup btn."
    },
    module: {
      required: true,
      type: Object,
    }
  },
  methods: {
    popUpDetails(detail: any) {
      this.popUpDetailsObj = detail;
      this.itemsSelected = detail.itemsOptions
    },
    popUpInPutData(data: any) {
      this.itemsSelected = [...data];
    },
    async loadModalData(viewName: string) {
      // @todo emit load data event, load data from the child component instead
      this.viewName = viewName
      this.$emit('loaded-popup-data')
    },
    async closeManageResourceModal() {
      this.viewName = ""
      this.popUpDetailsObj = {};
      this.itemsSelected = [];
    },
    async saveManageResourceModal() {
      // we should keep track of the data object/items
      // to update from this component
      // also the object should contain the end-point to send
      // the data to.
      const data: any = this.popUpDetailsObj;
      delete data.items;
      delete data.itemsOptions;

      const updateItem: ManyToManyFront = {
        ...data,
        items: JSON.stringify(this.itemsSelected),
        operationType: "PATCH"
      };
      // update many to many
      await updateManyToMany(updateItem, this.getEndPoint())
      this.viewName = ""
      this.popUpDetailsObj = {};
      this.itemsSelected = [];

    },

    /**
     * @todo this button should be in a named slot instead
     */
    async saveAll() {
      // Saves all resources on the competencies
      // list in association to the module

      const data: any = this.popUpDetailsObj;
      const {allItems} = this.popUpDetailsObj;
      delete data.items;
      delete data.itemsOptions;
      delete data.allItems;
      const updateItem = {
        ...data,
        items:  getSelectedIds(allItems),
        operationType: "PATCH"
      }
      // update many to many
      await updateManyToMany(updateItem, this.getEndPoint())
      this.viewName = ""
      this.popUpDetailsObj = {};
      this.itemsSelected = [];

    },

    /**
     * End point to get and update
     * manage resources reads and writes.
     */
    getEndPoint() {
      // relatedOrganizations
      const rO: string = getSelectedIds(this.module.orgs)

      // relatedCompetencies
      const rC: string = getSelectedIds(this.module.competencies)
      const id: string = this.module.id
      const endpoint = `module-competencies-resources/${id}?organizations=${rO}&competencies=${rC}`

      return endpoint
    }
  },

})

