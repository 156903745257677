
import {defineComponent, ref} from "vue";
import VueMultiselect from "vue-multiselect";
import HeadingRow from "@/components/HeadingRow.vue";
import {getItemList} from "@/services/httpService";
import ManyToManyFront from "@/types/ManyToManyFront";
export default defineComponent({
  name: "ManageEvaluators",
  components: {
    HeadingRow,
    VueMultiselect
  },
  props: {
    parentItem: {
      type: Object,
      required: true
    }
  },
  emits: ["popup-detail", "popup-input-data"],
  setup() {
    const users = ref<any[]>([]);
    const selected = ref<any[]>([]);
    const loading = ref(false);

    return {
      loading,
      selected,
      users
    }
  },
  watch: {
    selected(newList, oldList) {
      const selected = newList.map((user: any) => user.id)
      this.$emit('popup-input-data', selected)
    }
  },
  async mounted() {
    /**
     * We need to emit to the parent component,
     * some data, including, the endpoint to send the update
     * request to, and the item that owns the data
     * we're updating here.
     */
    const popUpDetails: ManyToManyFront = {
      collectionDocIdPrefix: "",
      collectionDocIdSuffix: this.parentItem.id,
      collectionPrefixName: "Evaluators",
      collectionSuffixName: "Modules",
    };

    this.$emit('popup-detail', popUpDetails)
    this.loading = true
    const data: any = await getItemList("manage-evaluators/" + this.parentItem.id);
    this.users = data.users
    const selected: any[] = []
    data.users.map((user: any) => {
      if (data.evaluatorsRelatedToModule.includes(user.id)) {
        selected.push(user)
      }
    })
    this.selected = selected
    this.loading = false
  }
})
