
import {defineComponent} from "vue";
import Competency from "@/types/Competency";
import {getItem, getItemList, saveItem, saveManyToManyFront} from "@/services/httpService";
import {getSelectedIds, arrayFromText} from "@/services/utils/cleanData";
import {useRoute} from "vue-router";
import GoBack from "@/components/GoBack.vue";
import VueMultiselect from "vue-multiselect";
import {parseInt} from "lodash";
import BaseInput from "@/components/BaseInput.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import MultiSelect from "@/components/MultiSelect.vue";

/**
 * id (numeric)
 * body
 * orgId (optional, this means the org owns the resource and won't be accessible to other orgs)
 * created date
 * created by
 * status (draft, published, decommission)
 * Competency version should be the id. We may do something more with other entity id's to form a more dynamic id.


 */

export default defineComponent({
  name: 'CompetencyForm',
  components: {
    GoBack,
    VueMultiselect,
    MultiSelect,
    BaseInput,
    BaseTextArea,
  },
  data() {
    const {params: {id}} = useRoute();
    const statusOptions: any[] = [
      {name: 'Draft', id: 'draft'},
      {name: 'Published', id: 'published'},
      {name: 'Decommission', id: 'decommission'},
    ];

    
    const organizations: any[] = []
    // this.competencyOrg == the organization name
    const competencyOrg = ''
    const competencyStatus = ''

    const relatedModules: any[] = []
    const relatedResources: any[] = []
    const relatedOrganizations: any[] = []
    const resources: any[] = []
    const modules: any[] = []

    let competency: Competency = {
      id: id ? `${id}` : '',
      name: '',
      body: '',
      orgId: '',
      created: '',
      status: competencyStatus,
      modules: [],
      resources: [],
      version: '',
      parentId: '',
      prevId:  '',
    }

    return {competency
      , statusOptions,
      competencyStatus,
      organizations,
      competencyOrg,
      relatedModules,
      relatedResources,
      relatedOrganizations,
      modules,
      resources,
    }
  },
  async mounted() {
    /**
     * @todo, we could do better and fetch the Competency together with
     * the org it belongs to, for better performance, instead of making two
     * requests.
     */

    /*const mods = await getItemList('modules');
    let moduleItems: any[] = [];
    mods.map((item: any) => {
      moduleItems.push({id: item.id, name: item.data.name})
    })
    this.modules = moduleItems;*/

    if (this.competency.id) {
      const data = await getItem('competencies', `${this.competency.id}`);

      /**
       * We need to set the selected organization name
       * to display its values as the selected option if selected
       */
      /*const filteredOrg = data.organizations.filter((org: any) => {
        if (org.id === data.data.orgId) return true
      });
      if (filteredOrg.length) {
        this.competency.orgId = filteredOrg[0].id
        this.competencyOrg = filteredOrg[0].name
      }*/

      if (!data.data) {
        // @todo set error
        return null
      }

      this.organizations = data.organizations;
      this.competencyStatus = data.data.status
      this.modules = data.modules;
      this.resources = data.resources;
      this.relatedModules = data.relatedModules;
      this.relatedResources = data.relatedResources;
      this.relatedOrganizations = data.relatedOrganizations;
      this.competency = {
        id: data.id,
        name: data.data.name,
        body: data.data.body,
        orgId: data.data.orgId,
        created: data.data.created,//todo set time on server instead or in db instance
        status: data.data.status,
        orgs: data.relatedOrganizations ? data.relatedOrganizations : [],
        modules: data.relatedModules ? data.relatedModules : [],
        resources: data.relatedResources ? data.relatedResources : [],
        version: data.data.version,
        parentId: this.competency.parentId,
        prevId:  this.competency.prevId,
      };
      //@todo if data is undefined set error!!

    }
    else {
      const data =
          await getItemList("organizations?extras-attached=modules&extras-attached-two=resources");
      this.organizations = data.organizations;
      this.modules = data.modules;
      this.resources = data.resources;

      console.log(data)
    }

  },
  methods: {
   /**
     * Creates new resources from newTag string, in
     * resources search and add.
     * @param{string} newTag
     */
    async createCompetencyResources(newTag: string) {
      const resourceTitles: string[] = arrayFromText(newTag)

      const resourceRelations: any = {
        competencyId: this.competency.id ? this.competency.id : ""
      }

      const items = {
        items: JSON.stringify([resourceTitles, resourceRelations])
      }

      const savedResources: any = await saveManyToManyFront({
        endpoint:  'resources-create',
        type: 'create',
        items: items
      });

      // create new resources if no this.competency.id
      // else create new resources related to this.competency.id

      // update view items
      this.relatedResources = [...savedResources, ...this.relatedResources]
      this.resources = [...this.resources]

      // also relate newly created resources to this competency on
      // save, to take to account those added during the normal
      // select from the list


    },
    async searchModules (searchQuery: string) {
      /*
      const results = await searchItems("modules", searchQuery);
      */
      //todo: search when term is 3+ chars
    },
    changeCompetencyStatus (event: any) {
      this.competency.status = event.target.options[event.target.options.selectedIndex].text
    },
    changeOrganizationId (event: any) {
      this.competency.orgId = event.target.options[event.target.options.selectedIndex].value
      this.competencyOrg = event.target.options[event.target.options.selectedIndex].text
    },
    updateModules (e: any) {
      //this.competency.modules!.push(module)
    },
    async onSubmit() {
      /**
       * convert this.competency.modules to an
       * array of ids only.
       */
      // modules.flatMap(m => m.id)
      const competency: any = {
        id: this.competency.id,
        name: this.competency.name,
        body: this.competency.body,
        //orgId: this.competency.orgId,
        status: this.competency.status,
        orgs: getSelectedIds(this.relatedOrganizations),
        modules: getSelectedIds(this.relatedModules),
        resources: getSelectedIds(this.relatedResources),
        version: parseInt(this.competency.version),
        parentId: this.competency.parentId,
        prevId:  this.competency.prevId,
      };

      // if update value/competency-id from url,
      // we send a PATCH request!

      const saved = await saveItem({
        endpoint: !this.competency.id ? 'competency-create': `competencies/${this.competency.id}`,
        type: !this.competency.id ? 'create' : 'update',
        item: competency
      });
    

      if (!saved.error) {
        this.competency.id = ''
        this.competency.body = ''
        this.competency.orgId = ''
        this.competency.created = ''
        this.competency.status = ''
        this.competency.modules = [],
        this.competency.orgs = [],
        this.competency.resources = [],
        this.competency.version = '',
        await this.$router.push('/competencies')
        return
      } else {
        // @todo set error in store
      }

      // @todo for saved.error
    }
  }
})
