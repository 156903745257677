import User from "@/types/User";
import {store} from "@/store";
import ManyToManyFront from "@/types/ManyToManyFront";
import {getSelectedIds} from "@/services/utils/cleanData";
declare const Buffer: any;
const BASEURL = process.env.VUE_APP_API_BASEURL
/**
 * @todo, we could check if user token is valid before using,
 * but was probably freshed on app load check should be implemented there, maybe read time before refreshing!
 * @todo
 */

// Reusable request method implementation:
async function requestData(url: string, data: any = {}, headers = {}, method = 'POST', loadingStatus = true) {

    const token = store.state.user?.accessToken
    const newHeaders: any = {
        value: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json",
        }

    };

    if (token) {
        newHeaders.value = {...newHeaders.value, "Authorization": `Bearer ${token}`,}
    }
    newHeaders.value = { ...newHeaders.value, ...headers}

    try {

        const payload = () => {
            if (method === 'POST' || method === 'PATCH' || method === 'DELETE') {
                return new URLSearchParams(data)
            } else return null
        }
        if (loadingStatus) await store.dispatch('SET_LOADING_STATUS', 'loading')

        const res: any = await fetch(url,{
            method: method,
            headers: newHeaders.value,
            body: payload(),
        } )

        if (loadingStatus) await store.dispatch('SET_LOADING_STATUS', 'loaded')

        if (res.ok) {

            return res.json();
        }
        else {
            const data = await res.json()
            await store.dispatch('SET_ERROR', {error: data.message ? data.message: res.statusText })

            /**
             * @todo render res.statusText on production instead
             */
            return { error: data.message ? data.message: res.statusText };
        }
    } catch (e) {
        if (loadingStatus) await store.dispatch('SET_LOADING_STATUS', 'loaded')
        const error = e as Error;
        //await store.dispatch('SET_ERROR', {error: e.message || 'Unknown Error!'});
        // console.log(e)
        return { error: error.message}
    }
}

export async function registerUserRequest(user: User) {
    const url = `${BASEURL}register`
    const res = await requestData(url, user);
    if (res.error) return res
    if (res.accessToken && res.email) return res.email

    // @todo for errors
}

export async function userLoginRequest(details: {email: string, password: string}){
    const url = `${BASEURL}login`;
    const auth = 'Basic ' + Buffer
        .from(details.email + ':' + details.password, 'utf-8')
        .toString('base64');
    // @todo encode email and password
    const headers = {'Authorization': auth}
    const res = await requestData(url, details, headers);
    if (res.error) return res
    if (res.accessToken) return res.accessToken

    else return 'ERRRO'; //@TODO

}

export async function logoutUser(){
    console.log('LOGOUT')

}
// @todo paginate requests
export async function getUsersList() {
    console.log("get the users!")
    /*const users = await db.collection('users').get()
    if (users.docs) {
        return users.docs
    } else {
        return {error: 'No records found!!'}
    }*/
    // const url = `${BASEURL}users`
    // const headers = {
    //     'Authorization': 'Bearer ' + localStorage.getItem('user_token')
    // }
    // const res = await requestData(url, {}, headers, 'GET')
    //
    // if (res.error) return res
    //
    // return res;
}

/**
 * Reusable saveItem, to save resources, org, competency, etc
 * should return object with a message key or error key
 */
export async function saveItem({endpoint, type, item}: any){
    const method = type === 'create' ? 'POST' : 'PATCH';
    const url = `${BASEURL}${endpoint}`

    if (type === 'create') {
        delete item.id;
    }
    const res = await requestData(
        url,
        {...item},
        {},
        method);

    if (res.message) {
        await store.dispatch('SET_MESSAGE', res.message)
    }
    if (res.error) {
        await store.dispatch('SET_ERROR', res.error);
    }


    return res

}

export async function saveManyToManyFront ({endpoint, type, items}: any){
    const url = `${BASEURL}${endpoint}`
    const method = type === "create" ? "POST" : "PATCH";

    const res = await requestData(
        url,
        {...items},
        {},
        method,
        false);

    if (res.message) {
        await store.dispatch('SET_MESSAGE', res.message)
    }
    if (res.error) {
        await store.dispatch('SET_ERROR', res.error);
    }


    return res
}

/**
 *
 * @param endpoint
 *   endpoint the the url string after BASEURL variable
 *   that's defined above.
 * @param id
 */
export async function getItem(endpoint: string, id: string){
    const url = `${BASEURL}${endpoint}/${id}`

    const data = await requestData(url, {}, {}, 'GET');

    return data;

}

export async function getCompetenciesResource(endpoint: string) {
    const url = `${BASEURL}${endpoint}`

    const data = await requestData(url, {}, {}, 'GET');

    return data;

}
/**
 * Debug
 * on full refresh token is undefined, like firebase is not yet initialized,
 * but when app reloads during development, it has it.
 * https://medium.com/firebase-developers/why-is-my-currentuser-null-in-firebase-auth-4701791f74f0
 * @param endpoint
 */
export async function getItemList(endpoint: string){
    const url = `${BASEURL}${endpoint}`
    const data = await requestData(url, {}, {}, 'GET')
    return data

}

export async function archiveItem(collectionId: string, item: any, endpoint: string) {
    /**
     * We need to convert Objects/Arrays,
     * into strings, incase.
     */
    if (item.competencies) item.competencies = getSelectedIds(item.competencies)
    if (item.modules) item.modules = getSelectedIds(item.modules)
    if (item.resources) item.resources = getSelectedIds(item.resources)
    if (item.orgs) item.orgs = getSelectedIds(item.orgs)
    if (item.items) item.items = getSelectedIds(item.items)
    if (item.evaluators) item.evaluators = getSelectedIds(item.evaluators)

    const newItem = {...item, archived: true}
    const url = `${BASEURL}${endpoint}`
    const res = await requestData(url, newItem, {}, 'PATCH');
    return res;

}

export async function getPreviewItem(endpoint: string, uriEx: string) {
    const url = `${BASEURL}${endpoint}/${uriEx}`
    // https://us-central1-badge-meta.cloudfunctions.net/abilibaseRestApi/programs/0N3Vt8HeE5zbW2OyFYxi/modules/049DMYwzp2O1b2KACXPipreview?view-as=html
    const data = await requestData(url, {}, {}, 'GET');

    return data;
}

/**
 * Not sure if we need the item,
 * as we have it's id and the backend handles
 * the rest!
 * @todo
 * @param collectionId
 * @param item
 * @param endpoint
 */
export async function exportItem(collectionId: string, item: any, endpoint: string){
    /**
     * Understanding Stream objects
     * https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
     * https://stackoverflow.com/questions/59394040/how-to-get-a-downloadable-file-from-a-readablestream-response-in-a-fetch-request
     */
    const url = `${BASEURL}${endpoint}`
    const token = localStorage.getItem('user_token');
    const headers: any = {
        "Accept": "application/md",
        "Authorization": `Bearer ${token}`,
    };
    //console.log(url)
    //const data = await requestData(url, {}, headers, 'GET');
    //return data;
    const res = await fetch(url, {headers});
    // const fileStream = res.body;
    //let reader = res.body
    //reader = reader.getReader();
   // console.log(res)
    const txt = await res.text()
    console.log(txt)

    return txt;
    /*
    if (fileStream) {
        //const fileStream = res.body;
        //const fileText = await res.text()
        const reader = fileStream.getReader();


        const stream = new ReadableStream({
            start(controller) {
                return pump();
                async function pump(): Promise<any> {
                    const {done, value } = await reader.read()
                    if (done) {
                        if (done) {
                            controller.close();
                            return;
                        }
                    }
                    // Enqueue the next data chunk into our target stream
                    controller.enqueue(value);
                    return pump();

                }
            }
        })
        console.log("+++++++++++++++++++++++++ res.blob() ++++++++++++++++++++++++++")
        console.log("+++++++++++++++++++++++++ res.blob() ++++++++++++++++++++++++++")
        console.log(stream.getReader())
        //console.log(fileStream)
        //console.log(fileStream.getReader())
        //console.log(await res.text())
        const blob = URL.createObjectURL(stream.getReader()) ;
        console.log(blob)
        console.log("+++++++++++++++++++++++++ res.blob() ++++++++++++++++++++++++++")
        console.log("+++++++++++++++++++++++++ res.blob() ++++++++++++++++++++++++++")

        //const file = window.URL.createObjectURL(res.body);
       // window.location.assign(file);
    }
    */

}

export async function searchItems(collectionId: string, term: string) {
    const url = `${BASEURL}search-item?collectionId=${collectionId}&term=${term}`
    const token = localStorage.getItem('user_token');
    const headers: any = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}`,
    };
    try {
        const res = await fetch(url,{
            method: "GET",
            headers: headers,
        });
        if (res.ok) {
            return res.json();
        }
        else {
            const data = await res.json();
            return { error: res.statusText ? res.statusText: data.message };
        }
    } catch (e) {
        const error = e as Error;
        return { error: error.message}
    }

}

/**
 * Silently updates many-to-many relationships, bypassing the loading
 * state, because it's not required in this workflow.
 * (added or deleted)
 * @param{ManyToManyFront} item
 * @param{string} endpoint
 */
export async function updateManyToMany(item: ManyToManyFront, endpoint = "update-relations") {
    const url = `${BASEURL}${endpoint}`
    const rItem: any = {...item};
    //const method = item.operationType === "added" ? "POST" : "DELETE";
    delete rItem.operationType;
    await requestData(url, rItem, {}, item.operationType, false)

    // @todo something with responce above!
}
