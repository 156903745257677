
import { defineComponent } from "vue";
import Organization from "@/types/Organization";
import { getItem, saveItem } from "@/services/httpService";
import { useRoute } from "vue-router";
import GoBack from "@/components/GoBack.vue";
import EntityFlag from "@/components/EntityFlag.vue";
import BaseInput from "@/components/BaseInput.vue";

export default defineComponent({
  name: "OrganizationForm",
  components: {
    GoBack,
    EntityFlag,
    BaseInput,
  },
  data() {
    const {
      params: { id },
    } = useRoute();
    let organization: Organization;

    organization = {
      id: id ? `${id}` : "",
      name: "",
      imageUrl: "",
    };
    return { organization };
  },

  async mounted() {
    if (this.organization.id) {
      const data = await getItem("organizations", `${this.organization.id}`);
      if (!data.data) {
        // @todo set error
        return null;
      }
      if (data.data) {
        this.organization = {
          id: data.id,
          name: data.data.name,
          imageUrl: data.data.imageUrl,
          archived: data.data.archived,
        };
        //@todo if data is undefined set error!!
      }
    }
  },
  methods: {
    async onSubmit() {
      const org: Organization = {
        //id: "this.organization.id",
        name: this.organization.name,
        imageUrl: this.organization.imageUrl,
        archived: this.organization.archived ? true : false,
      };

      console.log(Object.assign({ id: this.organization.id }, org));

      const saved = await saveItem({
        endpoint: !this.organization.id
          ? "organization-create"
          : `organizations/${this.organization.id}`,
        type: !this.organization.id ? "create" : "update",
        item: org,
      });

      if (!saved.error) {
        this.organization.name = "";
        this.organization.imageUrl = "";
        await this.$router.push("/organizations");

        // @todo: show message and clear error state
      } else {
        //console.log("ERROR")
        // @todo: error in state
      }

      //@todo: submit and clear values
    },
  },
});
