
import { defineComponent } from "vue";
import RenderImage from "@/components/RenderImage.vue";
import RenderMd from "@/components/RenderMd.vue";

export default defineComponent({
  name: "ImageDescription",
  components: {
    RenderImage,
    RenderMd,
  },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
  },
});
