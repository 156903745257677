import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label && _ctx.value)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.label) + ":", 1))
      : _createCommentVNode("", true),
    (_ctx.value)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          innerHTML: _ctx.renderMd(_ctx.value)
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}