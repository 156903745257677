
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getItem } from "@/services/httpService";
import { formatDate } from "@/services/utils/formatDateString";
import BaseOrderedList from "@/components/BaseOrderedList.vue";

export default defineComponent({
  name: "Program",
  components: {
    BaseOrderedList,
  },
  data() {
    const program: unknown = undefined;
    const orgs: unknown[] = [];
    const modulesRelated: unknown[] = [];
    const {
      params: { id },
    } = useRoute();
    return { program, id, orgs, modulesRelated };
  },
  async mounted() {
    const data = await getItem("programs", `${this.id}`);
    data.data.created = formatDate(data.data.created);
    data.data.updated = formatDate(data.data.updated);
    this.program = data;
    this.orgs = data.organizations;
    this.modulesRelated = data.modulesRelated;
  },
  methods: {
    getOrgName(orgId: string) {
      // eslint-disable-next-line
      const foundOrg: any = this.orgs.filter((org: any) => {
        return org.id === orgId;
      });
      if (foundOrg[0]) return foundOrg[0].name;
      else return "None";
    },
  },
});
