
import {defineComponent} from "vue";
import Evaluation from "@/types/Evaluation";
import {getItem, getItemList, saveItem} from "@/services/httpService";
import {getSelectedIds} from "@/services/utils/cleanData";
import {useRoute} from "vue-router";
import GoBack from "@/components/GoBack.vue";
import VueMultiselect from "vue-multiselect";
import EntityFlag from "@/components/EntityFlag.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
/**
 *
 */
export default defineComponent({
  name: 'EvaluationForm',
  components: {
    GoBack,
    EntityFlag,
    VueMultiselect,
    BaseInput,
    BaseTextArea
  },
  data() {
    const {params: {id}} = useRoute();

    const organizations: any[] = []
    const modules: any[] = []
    const evaluationOrg = ''

    const evaluation: Evaluation = {
      id: id ? `${id}` : '',
      name: '',
      body: '',
      orgId: {},
      modules: [],
      evaluationUrl: '',
      rubricUrl: '',
      archived: false,
    }

    return {evaluation , organizations, modules, evaluationOrg}
  },
  async mounted() {

    if (this.evaluation.id) {
      const data = await getItem('evaluations', `${this.evaluation.id}`);
      if (!data.data) {
        // @todo set error
        return null
      }

      const mods = await getItemList('modules');
      let moduleItems: any[] = [];
      mods.map((item: any) => {
        moduleItems.push({id: item.id, name: item.data.name})
      })
      this.modules = moduleItems;

      this.organizations = data.organizations;
      const filteredOrg = data.organizations.filter((org: any) => {
        if (org.id === data.data.orgId) return true
      });
      if (filteredOrg.length) {
        //this.evaluation.orgId = filteredOrg[0].id
        this.evaluationOrg = filteredOrg[0].name
      }
      this.evaluation = {
        id: data.id,
        name: data.data.name,
        body: data.data.body,
        orgId: data.data.orgId,
        evaluationUrl: data.data.evaluationUrl,
        rubricUrl: data.data.rubricUrl,
        modules: data.relatedModules,
        archived: data.data.archived,
      };

    }
    else {
      const data = await getItemList('organizations?extras-attached=modules');
      this.modules = data.modules
      this.organizations = data.organizations;
    }

  },
  methods: {
    updateModules (module: any) {
      this.evaluation.modules!.push(module)
    },
    selectOrg (org: any) {
      this.evaluation.orgId = org
    },
    changeOrganizationId (event: any) {
      this.evaluation.orgId = event.target.options[event.target.options.selectedIndex].value
      this.evaluationOrg = event.target.options[event.target.options.selectedIndex].text
    },
    async onSubmit() {
      // const {orgId} = this.evaluation.orgId
      const evaluation: any = {
        id: this.evaluation.id,
        name: this.evaluation.name,
        body: this.evaluation.body,
        orgId: this.evaluation.orgId,
        modules: getSelectedIds(this.evaluation.modules),
        evaluationUrl: this.evaluation.evaluationUrl,
        rubricUrl: this.evaluation.rubricUrl,
        archived: this.evaluation.archived,
      };


      const saved = await saveItem({
        endpoint: !this.evaluation.id ? 'evaluation-create': `evaluations/${this.evaluation.id}`,
        type: !this.evaluation.id ? 'create' : 'update',
        item: evaluation
      });

      if (!saved.error) {
        // clear form values
        this.evaluation.id = ''
        this.evaluation.name = ''
        this.evaluation.body = ''
        this.evaluation.orgId = ''
        this.evaluation.modules = [],
        this.evaluation.evaluationUrl = '',
        this.evaluation.rubricUrl = '',
        await this.$router.push('/evaluations')
        return
      } else {
        // console.log('++++++++++++++ SHOW MESSAGE ++++++++++++++++++++++++')
      }

    }
  }
})
