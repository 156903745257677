
import { defineComponent } from "vue";

/**
 * linkTo is the name of the vue route eg 'Module'
 * items is the array of items to display in the list
 * idKey is the unique id (object property) in each item in the items array.
 * displayKey the object property that holds the text to display.
 */
export default defineComponent({
  name: "BaseOrderedList", // ContentRowList
  props: {
    listTitle: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      required: true,
    },
    idKey: {
      type: String,
      default: "id",
    },
    displayKey: {
      type: String,
      required: true,
    },
  },
});
