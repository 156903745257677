
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "MyAccount",
  setup() {
    const store = useStore();
    const user = store.state.user;
    const claims = ref({});
    onMounted(async () => {
      const idTokenResult = await user?.getIdTokenResult();
      claims.value = idTokenResult?.claims;
    });
    return { user, claims };
  },
});
