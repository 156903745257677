
import {defineComponent} from "vue";
import GoBack from "@/components/GoBack.vue";
import EntityFlag from "@/components/EntityFlag.vue";
import {useRoute} from "vue-router";
import {useStore, store} from "@/store";
import WebHook from "@/types/WebHook";
import {getItem, getItemList, saveItem} from "@/services/httpService";
import {getSelectedIds} from "@/services/utils/cleanData";
import FormError from "@/components/FormError.vue";
import BaseInput from "@/components/BaseInput.vue";
import MultiSelect from "@/components/MultiSelect.vue";
import VueMultiselect from "vue-multiselect";

export default defineComponent({
  name: 'WebHookForm',
  components: {
    GoBack,
    EntityFlag,
    FormError,
    VueMultiselect,
    BaseInput,
    //MultiSelect,
  },
  data() {
    const {params: {id}} = useRoute();
    const store: any = useStore();
    const organizations: any[] = []
    const webhookevents: any[] = []
    const relatedOrganizations: any[] = []
    const relatedWebhookEvents: any[] = []
    const webhookOrg = ''
    const error = ''
    const webhook: WebHook = {
      id: id ? `${id}` : '',
      title: '',
      url: '',
      event: '',
      orgs: [],
      webhookevents: [],
      archived: false
    }

    return {
      webhook,
      organizations,
      webhookOrg,
      relatedOrganizations,
      webhookevents,
      relatedWebhookEvents,
      store,
      error,
    };
  },
  async mounted() {
    if (this.webhook.id) {
      const data = await getItem('webhooks', `${this.webhook.id}?attached=organizations`);
      if (!data.data) {
        return null
      }
      this.organizations = data.organizations;
      this.webhookevents = data.webhookevents;
      this.relatedOrganizations = data.relatedOrganizations;
      this.relatedWebhookEvents = data.relatedWebhookEvents;
      this.webhook = {
        id: data.id,
        title: data.data.title,
        url: data.data.url,
        orgs: data.relatedOrganizations ? data.relatedOrganizations : [],
        webhookevents: data.relatedWebhookEvents ? data.relatedWebhookEvents : [],
        event: data.data.event,
        archived: data.data.archived
      }
    } else {
      const data = await getItemList('organizations?extras-attached=webhookevents');
      this.organizations = data.organizations;
      this.webhookevents = data.webhookevents;
    }
  },

  methods: {
    /**
     * Creates a new webhook term
     * @param{string} newTag
     */
    async createWebhookTerm(newTag: string) {
      const saved = await saveItem({
        endpoint: 'webhook-term-create',
        type: 'create',
        item: {term: newTag}
      });

      this.relatedWebhookEvents = [...this.relatedWebhookEvents, saved]

    },
    async onSubmit() {
      if (this.error) this.error = "";
      if (!this.relatedOrganizations.length) {
        const message = "A webhook should have at least one organization";
        this.error = message
        // await this.store.dispatch("SET_ERROR", {message: "here"});
        return
      }
      const webhook: WebHook = {
        id: this.webhook.id,
        title: this.webhook.title,
        orgs: getSelectedIds(this.relatedOrganizations),
        webhookevents: getSelectedIds(this.relatedWebhookEvents),
        url: this.webhook.url,
        event: this.webhook.event,
        archived: this.webhook.archived
      }

      const saved = await saveItem({
        endpoint: !this.webhook.id ? 'webhook-create': `webhooks/${this.webhook.id}`,
        type: !this.webhook.id ? 'create' : 'update',
        item: webhook
      });

      if (!saved.error) {
        this.webhook.id = ''
        this.webhook.title = ''
        this.webhook.url = ''
        this.webhook.event = ''
        this.webhook.orgs = []
        this.webhook.webhookevents = []
        this.webhook.archived = false
        await this.$router.push('/webhooks')
        return
      }
      // handle errors too!
    }
  }
})

