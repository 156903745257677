
import { defineComponent, ref } from "vue";
import { getItemList } from "@/services/httpService";
import { useStore } from "@/store";

/**
 * Displays modules that the current logged in user
 * is added as an evaluator.
 */
export default defineComponent({
  name: "MyModules",
  setup() {
    const store = ref(useStore());
    const modules = ref<[]>([]);
    return { store, modules };
  },
  async mounted() {
    const data = await getItemList("my-modules");
    if (data.error) return;
    this.modules = data.modules;
  },
});
