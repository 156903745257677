
import { defineComponent, ref, onMounted } from "vue";

import { getItemList, updateManyToMany } from "@/services/httpService";
import { getSelectedIds } from "@/services/utils/cleanData";
import BaseModalTrigger from "@/components/BaseModalTrigger.vue";
import BaseModalView from "@/components/BaseModalView.vue";
import MultiSelect from "@/components/MultiSelect.vue";
import ManyToManyFront from "@/types/ManyToManyFront";

export default defineComponent({
  name: "Users",
  components: {
    BaseModalTrigger,
    BaseModalView,
    MultiSelect,
  },
  setup() {
    // eslint-disable-next-line
    const users = ref<any>({
      userList: [],
      usersWithRoles: [],
      selectedSuperAdmins: [],
      usersToSelect: [],
    });
    const error = ref<string>("");

    const loadUserData = async () => {
      const usersData = await getItemList("get-users");
      users.value = usersData;
    };

    onMounted(async () => {
      await loadUserData();
    });

    const rolesString = (uid: string) => {
      let stringValue = "None";
      // eslint-disable-next-line
      users.value.usersWithRoles.map((user: any) => {
        if (user?.id === uid) {
          // @todo roles is an array, include that
          stringValue = user.roles[0];
        }
      });
      return stringValue;
    };

    const openSuperAdminModal = () => {
      // Use the data we already have
      const { userList } = users.value;
      users.value.selectedSuperAdmins = userList.filter(
        // eslint-disable-next-line
        (user: any) => user?.customClaims?.super_admin
      );
      users.value.usersToSelect = userList; // .filter((user: any) => !user?.customClaims?.super_admin)
    };

    const closeSuperAdminModal = async () => {
      await loadUserData();
    };

    const saveSuperAdminModal = async () => {
      const selectedIds = getSelectedIds(users.value.selectedSuperAdmins);

      const item: ManyToManyFront = {
        operationType: "POST",
        collectionPrefixName: "",
        collectionSuffixName: "",
        items: selectedIds,
      };
      await updateManyToMany(item, "update-super-admins");
      await loadUserData();
    };

    return {
      users,
      error,
      rolesString,
      openSuperAdminModal,
      closeSuperAdminModal,
      saveSuperAdminModal,
    };
  },
});
