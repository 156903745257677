
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getItem } from "@/services/httpService";
import BaseOrderedList from "@/components/BaseOrderedList.vue";
import HeadingRow from "@/components/HeadingRow.vue";
import ImageDescription from "@/components/ImageDescription.vue";
export default defineComponent({
  name: "Organization",
  components: {
    ImageDescription,
    HeadingRow,
    BaseOrderedList,
  },
  data() {
    const organization: unknown = undefined;
    const webhooks: unknown[] = [];
    const {
      params: { id },
    } = useRoute();
    return { organization, webhooks, id };
  },
  async mounted() {
    const data = await getItem("organizations", `${this.id}`);
    this.webhooks = data.webhooks;
    this.organization = data;
    //@todo if data is undefined set error!!
  },
});
