
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getPreviewItem } from "@/services/httpService";
import { formatDate } from "@/services/utils/formatDateString";
import RenderImage from "@/components/RenderImage.vue";
import RenderMd from "@/components/RenderMd.vue";
import BaseOrderedList from "@/components/BaseOrderedList.vue";

export default defineComponent({
  name: "ProgramModulePreview",
  components: {
    RenderImage,
    RenderMd,
    BaseOrderedList,
  },
  data() {
    const viewData: unknown = {};
    const markDownData: unknown = {};
    const resources: unknown = { learning: [], practice: [] };
    const viewAs: unknown = "";
    const {
      params: { moduleId, programId },
    } = useRoute();
    return { viewData, moduleId, programId, viewAs, resources, markDownData };
  },
  async mounted() {
    const query = useRoute().query;
    if (query["view-as"]) {
      /**
       * Set unknown format when view-as value
       * doesn't equal the implemented inputs
       * like Credly.
       * @todo
       */
      this.viewAs = query["view-as"];
      switch (query["view-as"]) {
        case "mod":
          break;
        default:
      }
    }

    const uriExtras = query["view-as"]
      ? `?view-as=${query["view-as"]}`
      : "?view-as=html";
    const data = await getPreviewItem(
      "programs",
      `${this.programId}/modules/${this.moduleId}/preview${uriExtras}`
    );
    if (!data.error) {
      data.module.data.created = formatDate(data.module.data.created);
      data.module.data.updated = formatDate(data.module.data.updated);
      data.module.data.expiration = formatDate(data.module.data.expiration);
      const mdData = {
        program: {
          id: data.program.id,
          ...data.program.data,
        },
        module: {
          id: data.module.id,
          ...data.module.data,
        },
        organization: {
          id: data.relatedOrganizations.id,
          ...data.relatedOrganizations.data,
        },
      };
      // console.log(data);
      this.viewData = data;
      this.markDownData = mdData;
    }
  },
});
