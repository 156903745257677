
import {defineComponent} from "vue";
import Program from "@/types/Program";
import {getItem, getItemList, saveItem} from "@/services/httpService";
import {useRoute} from "vue-router";
import GoBack from "@/components/GoBack.vue";
import EntityFlag from "@/components/EntityFlag.vue";
import {formatDate} from "@/services/utils/formatDateString";
import VueMultiselect from "vue-multiselect";
import {getSelectedIds} from "@/services/utils/cleanData";
import BaseInput from "@/components/BaseInput.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";

export default defineComponent({
  name: 'ProgramForm',
  components: {
    GoBack,
    EntityFlag,
    VueMultiselect,
    BaseInput,
    BaseTextArea,
  },
  data() {

    const organizations: any[] = []
    const modules: any[] = []
    const programOrg = ''
    const program: Program = {
      id: '',
      slug: '',
      name: '',
      orgId: '',
      modules: [],
      orgs: [],
      competenciesInstructions: '',
      created: '',
      updated: '',
      archived: false,
      lastUpdatedBy: '',
      copyright: '',
    }

    return {program, organizations, programOrg, modules}
  },
  async mounted() {
    const {params: {id}} = useRoute();
    this.program = {...this.program, id: id ? `${id}` : '',}

    if (this.program.id) {
      const data = await getItem('programs', `${this.program.id}`);
      if (!data.data) {
        // @todo set error
        return null
      }
      if (data.data) {
        console.log(data.organizationsRelated)
        this.program.name = data.data.name
        this.program.orgId = data.data.orgId
        this.program.slug = data.data.slug
        this.program.modules = data.modulesRelated
        this.program.orgs = data.organizationsRelated
        this.program.competenciesInstructions = data.data.competenciesInstructions
        this.program.created = formatDate(data.data.created),
        this.program.updated = formatDate(data.data.updated),
        this.program.archived = data.data.archived ? data.data.archived : false
        this.program.lastUpdatedBy = data.data.lastUpdatedBy
        this.program.copyright = data.data.copyright


      }
      this.modules = data.modules
      this.organizations = data.organizations

      /**
       * Set orgId and organization name in state.
       */
      const filteredOrg = data.organizations.filter((org: any) => {
        if (org.id === data.data.orgId) return true
        else return false
      });

      if (filteredOrg.length) {
        this.program.orgId = filteredOrg[0].id
        this.programOrg = filteredOrg[0].name
      }

    }
    else {
      const data = await getItemList('organizations?extras-attached=modules');
      this.modules = data.modules
      this.organizations = data.organizations;
    }

  },
  methods: {
    async onSubmit() {
      const organization = this.program.orgs[0] ? this.program.orgs[0] : this.program.orgs
      const {id: orgId} = organization

      if (!orgId) {
        this.$store.dispatch("SET_ERROR", {error: "A program must belong to an organization!"})
        return
      }
      const program: Program = {
        name: this.program.name,
        slug: this.program.slug,
        orgId: this.program.orgId,
        competenciesInstructions: this.program.competenciesInstructions,
        modules: getSelectedIds(this.program.modules),
        orgs: getSelectedIds([{id: orgId}]),
        archived: this.program.archived,
        copyright: this.program.copyright,
      }

      const saved = await saveItem({
        endpoint: !this.program.id ? 'program-create' : `programs/${this.program.id}`,
        type: !this.program.id ? 'create' : 'update',
        item: program
      });

      if (saved.message) {
        this.program.name = ''
        this.program.slug = ''
        this.program.orgId = ''
        this.program.modules = []
        this.program.orgs = []
        this.program.archived = false
        this.program.copyright = ''
        this.program.competenciesInstructions = ''
        await this.$router.push('/programs')
        return
      }
    },
    changeOrganizationId (event: any) {
      this.program.orgId = event.target.options[event.target.options.selectedIndex].value
      this.programOrg = event.target.options[event.target.options.selectedIndex].text
    },
  }
})
