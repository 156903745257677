
import { defineComponent, ref } from "vue";
import { getItemList, searchItems } from "@/services/httpService";
import { useStore } from "@/store";
// import SearchForm from "@/components/SearchForm.vue";
import RenderImage from "@/components/RenderImage.vue";

export default defineComponent({
  name: "Organizations",
  components: {
    // SearchForm,
    RenderImage,
  },
  data() {
    const organizations: unknown[] = [];
    return { organizations };
  },
  setup() {
    const store = ref(useStore());
    return { store };
  },
  async mounted() {
    const data = await getItemList("organizations");
    // const fakes = await getItemList('generate-fakes');
    // console.log(fakes)
    this.organizations = data;
  },
  methods: {
    // async searchOrganizations(term: string) {
    //   // TODO: search organization
    // },
    async searchTermSubmit(term: string) {
      /*const data = await getItemList(`organizations?search=${term}`);
      this.organizations = data;
      const message = `Search results for term: ${term}, yielded ${data.length} items. `
      await this.store.dispatch('SET_MESSAGE',message)*/
      const items = await searchItems("organizations", term);
      this.organizations = items;
    },
  },
});
