import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "aria-labelledby"]
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal",
    id: _ctx.modalId,
    tabindex: "-1",
    "aria-labelledby": _ctx.modalId +'Label',
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass('modal-dialog modal-dialog-centered '  +_ctx.modalSize)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", {
            class: "modal-title",
            id: _ctx.modalId +'Label'
          }, _toDisplayString(_ctx.modalTitle), 9, _hoisted_4),
          _createElementVNode("button", _mergeProps({
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": "Close"
          }, {
                          ..._ctx.$attrs,
                          onClick: _ctx.close
                          }), null, 16)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", _mergeProps({
            type: "button",
            class: "btn btn-secondary",
            "data-bs-dismiss": "modal"
          }, {
                          ..._ctx.$attrs,
                          onClick: _ctx.close
                          }), _toDisplayString(_ctx.closeBtnText), 17),
          _createElementVNode("button", _mergeProps({
            type: "button",
            class: "btn btn-primary"
          }, {
                  ..._ctx.$attrs,
                  onClick: _ctx.save
                  }), _toDisplayString(_ctx.saveBtnText), 17)
        ])
      ])
    ], 2)
  ], 8, _hoisted_1))
}