
/**
 * Just for testing ,
 * @todo: delete this view.
 * @/components/AbilibaseMultiSelect.vue is also under test, and
 * development.
 */
import {defineComponent} from "vue";
import AbilibaseMultiSelect from "@/components/AbilibaseMultiSelect.vue";

export default defineComponent({
  name: 'TestComponent',
  components: {
    AbilibaseMultiSelect
  },
  data() {
    return {
      days: [
        {title: "Saturday", shortcut: "sat"},
        {title: "Sunday", shortcut: "sun"},
        {title: "Monday", shortcut: "mon"},
        {title: "Tuesday", shortcut: "tue"},
      ],
      selectedDays: [
        {title: "Monday", shortcut: "mon"},
        {title: "Sunday", shortcut: "sun"},
      ]
    }
  }
})
