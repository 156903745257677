
import { defineComponent } from "vue";
import { formatGoogleDriveImageUri } from "@/services/utils/cleanData";
// import helpers from "@/mixins/helpers";

export default defineComponent({
  name: "RenderImage",
  // mixins:[helpers],
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: "100%",
    },
    maxHeight: {
      type: String,
      default: "100%",
    },
  },
  setup() {
    const formatUri = (uri: string) => {
      return formatGoogleDriveImageUri(uri);
    };

    return { formatUri };
  },
});
