import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mt-5" }
const _hoisted_2 = { class: "col-sm-3" }
const _hoisted_3 = { class: "col-sm-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RenderImage = _resolveComponent("RenderImage")!
  const _component_render_md = _resolveComponent("render-md")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RenderImage, {
        "image-url": _ctx.imageUrl,
        "alt-text": _ctx.altText
      }, null, 8, ["image-url", "alt-text"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_render_md, { value: _ctx.description }, null, 8, ["value"]),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}