
import { defineComponent, ref } from "vue";
import { getItemList, searchItems } from "@/services/httpService";
import { useStore } from "@/store";
// import SearchForm from "@/components/SearchForm.vue";
import { organizationsNames } from "@/services/utils/cleanData";

export default defineComponent({
  name: "Programs",
  components: {
    // SearchForm
  },
  data() {
    const programs: unknown[] = [];
    const orgs: unknown[] = [];
    const orgsRelated: unknown[] = [];
    return { programs, orgs, orgsRelated };
  },
  setup() {
    const store = ref<unknown>(useStore());
    return { store };
  },
  async mounted() {
    const data = await getItemList("programs");
    this.programs = data.programs;
    this.orgs = data.organizations;
    this.orgsRelated = data.organizationsRelatedToPrograms;
  },
  methods: {
    organizationsNames(id: string) {
      return organizationsNames(this.orgs, this.orgsRelated, id);
    },
    getOrgName(orgId: string) {
      // eslint-disable-next-line
      const foundOrg: any = this.orgs.filter((org: any) => {
        return org.id === orgId;
      });
      if (foundOrg[0]) return foundOrg[0].name;
    },
    // searchOrganizations(term: string) {
    //   console.log(term)
    // },
    async searchTermSubmit(term: string) {
      /*const data = await getItemList(`organizations?search=${term}`);
      this.programs = data;
      const message = `Search results for term: ${term}, yielded ${data.length} items. `
      await this.store.dispatch('SET_MESSAGE',message)*/
      const data = await searchItems("resources", `${term}`);
      this.programs = data;
    },
  },
});
