
import {defineComponent} from "vue";
import {archiveItem} from "@/services/httpService"
export default defineComponent({
  /**
   * EntityFlag contains a re-usable component
   * to archive other entities.
   */
  name: "EntityFlag",
  props: {
    type: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
  },
  data() {
    return {}
  },
  methods: {
    async flagEntity() {
      const isArchived = await archiveItem( this.type, this.item, this.endpoint);
      console.log("|||||||||||||||||| isArchived ||||||||||||||||||||")
      console.log("|||||||||||||||||| isArchived ||||||||||||||||||||")
      console.log(isArchived)
      console.log("|||||||||||||||||| isArchived ||||||||||||||||||||")
      console.log("|||||||||||||||||| isArchived ||||||||||||||||||||")
      switch (this.type) {
        case 'Organizations':
            await this.$router.push("/organizations")
            return
        case 'Modules':
          await this.$router.push("/modules")
          return
        case 'Resources':
          await this.$router.push("/resources")
          return
        case 'Programs':
          await this.$router.push("/programs")
          return
        case 'Evaluations':
          await this.$router.push("/evaluations")
          return
        case 'WebHooks':
          await this.$router.push("/webhooks")
          return

      }
    }
  }
});

