
/**
 * Renders a col inside a row
 * colName maybe be the field name/title while
 * colText the the field value to output
 */
import {defineComponent} from "vue";
export default defineComponent({
  name: 'ContentRow',
  props: {
    colName: {
      type: String,
      required: true
    },
    colText: {
        type: String,
        required: true
    }
  }
})

