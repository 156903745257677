import { marked } from "marked";
import * as mustache from "mustache";

export function markdownToHtml(markdown: string): string {
  return !markdown ? "" : marked.parse(markdown);
}

/**
 *
 * @param{any} data
 * @param{string} text
 */
// eslint-disable-next-line
export const renderMdTemplate = (data: unknown, text: string): any => {
  const outputString = mustache.render(text, data);

  return outputString;
};

/**
 * Takes id from google drive image uri and reformats, the
 * src string in a way that it can be loaded via browser
 * and avoid the CORB error/issue @
 * https://chromestatus.com/feature/5629709824032768
 * @param{string} uri
 */
export function formatGoogleDriveImageUri(uri: string): string {
  if (!uri)
    return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWBAMAAADOL2zRAAAAG1BMVEXMzMyWlpaqqqq3t7fFxcW+vr6xsbGjo6OcnJyLKnDGAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABAElEQVRoge3SMW+DMBiE4YsxJqMJtHOTITPeOsLQnaodGImEUMZEkZhRUqn92f0MaTubtfeMh/QGHANEREREREREREREtIJJ0xbH299kp8l8FaGtLdTQ19HjofxZlJ0m1+eBKZcikd9PWtXC5DoDotRO04B9YOvFIXmXLy2jEbiqE6Df7DTleA5socLqvEFVxtJyrpZFWz/pHM2CVte0lS8g2eDe6prOyqPglhzROL+Xye4tmT4WvRcQ2/m81p+/rdguOi8Hc5L/8Qk4vhZzy08DduGt9eVQyP2qoTM1zi0/uf4hvBWf5c77e69Gf798y08L7j0RERERERERERH9P99ZpSVRivB/rgAAAABJRU5ErkJggg==";

  const pattern = /^https:\/\/drive\.google\.com\/file\/d\/(.*?)\/view/;

  if (pattern.test(uri)) {
    // eslint-disable-next-line
    const matches: any = uri.match(pattern);
    if (matches[1]) {
      return `https://drive.google.com/uc?id=${matches[1]}`;
    }
    return uri;
  }

  return uri;
}

export function organizationsNames(
  organizations: unknown[],
  // eslint-disable-next-line
  relationIds: any,
  id: string
): string {
  if (!relationIds || !organizations?.length) return "None";

  const ids: unknown[] = relationIds[id];
  if (!ids?.length) return "None";

  let name = "";
  // eslint-disable-next-line
  organizations.map((organization: any) => {
    if (ids.includes(organization.id)) {
      if (!name) name = organization.name;
      else name = `${name}, ${organization.name}`;
    }
  });
  return name;
}

export function getSelectedIds(data: unknown): string {
  if (!data) return "[]";

  const items: unknown[] = [];
  // @todo remove json and test

  // eslint-disable-next-line
  const itemsObject: any = JSON.parse(JSON.stringify(data));

  // eslint-disable-next-line
  itemsObject.map((item: any) => {
    items.push(item.id);
  });

  return JSON.stringify(items);
  //return  items;
}

// eslint-disable-next-line
export function filterOrgs(orgsList: any[], orgsId: any[]): any[] {
  if (!orgsList || !orgsId) return [];

  const results = orgsList.filter(({ id }) => orgsId.includes(id));
  return results;
}

export function trimBodyCharacters(text: string): string {
  if (text.length < 50) return text;
  return text.substring(0, 50).trimEnd() + "...";
}

/**
 *
 * @param{string} text
 * @return{string[]} items
 */
export function arrayFromText(text: string): string[] {
  const items: string[] = text.split("\n");
  return items;
}
