// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require("moment");

// eslint-disable-next-line
export function formatDate(date: string): any {
  if (date) {
    const dateString = new Date(date).toISOString();
    const formatted = moment(dateString).format("LLL");
    return formatted;
  }
}
