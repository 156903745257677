
import { store, useStore } from "@/store";
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "Status",
  setup() {
    const store = ref(useStore());

    return { store };
  },
  methods: {
    async removeStatus() {
      //if (store.getters.error) {
      await store.dispatch("SET_ERROR", "");
      //} else if  (store.getters.message) {
      await store.dispatch("SET_MESSAGE", "");
      //}
    },
  },
});
