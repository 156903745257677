
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { getPreviewItem } from "@/services/httpService";
import { formatDate } from "@/services/utils/formatDateString";
import RenderMd from "@/components/RenderMd.vue";

export default defineComponent({
  name: "ProgramPreview",
  components: { RenderMd },
  data() {
    const program: unknown = undefined;
    const modules: unknown[] = [];
    const organization: unknown = {};
    const {
      params: { id },
    } = useRoute();
    return { program, id, organization, modules };
  },
  async mounted() {
    const data = await getPreviewItem("programs", `${this.id}/preview`);
    data.data.created = formatDate(data.data.created);
    data.data.updated = formatDate(data.data.updated);
    this.program = data;
    this.organization = data.organization;
    this.modules = data.modules;
    //@todo if data is undefined set error!!
  },
});
